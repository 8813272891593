import React, { useEffect, useState } from "react";
import { Carousel } from "react-carousel-minimal";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@mui/icons-material/Edit";
import ModalPhoto from "./ModalPhoto/ModalPhoto";
import ModalMenu from "./ModalPhoto/ModalMenu/ModalMenu";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import "./SectionPhoto.css";
const SectionPhoto = (props) => {
  const [Caption, setCaption] = useState([]);
  const [isprint, setprintmode] = useState(false);
  const [photosList, setphotolist] = useState(props.bien[0].url_photo);
  const { fileServerUrl } = require("../../../../shared/values/environnement");
  const [editmode, seteditmode] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    console.log(props.bien, "biennnnn");
  }, [props.bien, Caption, isprint, photosList]);
  let proxyUrl = "https://cors.application.wealzy.com/";
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    var photolistnocors = [];
    let proxyUrl = "https://cors.application.wealzy.com/";
    console.log("photosList", photosList);
    if (photosList) {
      photosList.map((photo) => {
        if (photo.includes("bien/photos")) {
          photolistnocors.push(photo);
        } else {
          photolistnocors.push(proxyUrl + photo);
        }
      });
      console.log(photolistnocors);
      setphotolist(photolistnocors);
    }
  }, [props.bien]);

  useEffect(() => {
    console.log(props.print);
    setprintmode(props.print);
    seteditmode(false);
  }, [props.print]);

  const parseBienProps = () => {
    let data = [];
    props.bien[0].url_photo.map((url, index) => {
      data.push({
        image: url,
        caption: Caption,
      });
    });
    console.log(data);
    return data;
  };

  const handleModalPhoto = (index) => {
    console.log(index);
    let phototemp = [photosList[index]];
    photosList.map((photo, i) => {
      if (i !== index) {
        phototemp.push(photo);
      }
      setphotolist([...phototemp]);
    });
  };

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
    backgroundColor: "transparent",
    color: "#122659",
  };

  useEffect(()=>{
     console.log('aaaaaaaaa',props.img)
  },[props])
  return (
    // <div style={{ height: "80%" }}>
    //   {isprint || editmode ? (
    //     <div>
    //       {editmode && (
    //         <div className="print-header">
    //           {" "}
    //           <Button
    //             // style={{color:"#122659"}}
    //             onClick={() => {
    //               seteditmode(false);
    //               setprintmode(false);
    //             }}
    //             startIcon={<ArrowBackIcon />}
    //           >
    //             <a>Retour</a>
    //           </Button>
    //           <ModalMenu bien={photosList} update={handleModalPhoto} />
    //         </div>
    //       )}
    //       <Grid
    //         container
    //         spacing={2}
    //         style={{ width: "100%", height: "fitt-content" }}
    //       >
    //         <Grid item xs={12} md={12}>
    //           <Grid
    //             item
    //             xs={12}
    //             md={12}
    //             style={{
    //               width: "100%",
    //               height: "80%",
    //               display: "flex",
    //               justifyContent: "center",
    //             }}
    //           >
    //             <img
    //               className="image_principale"
    //               src={photosList[0]}
    //               alt="..."
    //             />
    //           </Grid>
    //         </Grid>
    //         <Grid
    //           container
    //           xs={12}
    //           md={12}
    //           spacing={3}
    //           style={{
    //             display: "flex",
    //             justifyContent: "start",
    //             alignItems: "center",
    //             width: "100%",
    //             paddingLeft: "20px",
    //           }}
    //         >
    //           {photosList.map((url, index) => {
    //             if (index > 0 && index < 9) {
    //               return (
    //                 <Grid item xs={12} sm={12} md={12} style={{}}>
    //                   <img className="images" src={url} alt="..." />
    //                 </Grid>
    //               );
    //             }
    //           })}
    //         </Grid>
    //       </Grid>
    //     </div>
    //   ) : (
    //     <div style={{ textAlign: "center" }}>
    //       <div
    //         style={{
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",
    //         }}
    //       >
    //         <Button
    //           variant="contained"
    //           style={{ backgroundColor: "#122659" }}
    //           onClick={() => {
    //             seteditmode(true);
    //           }}
    //         >
    //           Pour modifier ou consulter l'affichage de cette section dans le
    //           pdf cliquez ici.
    //         </Button>
    //       </div>
    //       <div
    //         style={{
    //           padding: "0 20px",
    //         }}
    //       >
    //         <Carousel
    //           data={parseBienProps()}
    //           time={1000}
    //           width="850px"
    //           height="400px"
    //           captionStyle={captionStyle}
    //           radius="10px"
    //           slideNumber={true}
    //           slideNumberStyle={slideNumberStyle}
    //           captionPosition="bottom"
    //           automatic={true}
    //           dots={true}
    //           pauseIconColor="white"
    //           pauseIconSize="40px"
    //           slideBackgroundColor="darkgrey"
    //           slideImageFit="cover"
    //           thumbnails={true}
    //           thumbnailWidth="100px"
    //           style={{
    //             textAlign: "center",
    //             maxWidth: "850px",
    //             maxHeight: "500px",
    //             margin: "40px auto",
    //           }}
    //         />
    //       </div>
    //     </div>
    //   )}
    // </div>

    <div style={{ height: "90%" }}>
      {
        <img
          style={{ width: "100%", height: "100%" }}
          src={props.img}
          alt="img"
        />
      }
    </div>
  );
};
export default SectionPhoto;
