import React, { useState, useEffect } from "react";
import "./SectionPlan.css";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import axios from "axios";
import Button from "@mui/material/Button";
import Grid from "@material-ui/core/Grid";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import DeleteIcon from "@mui/icons-material/Delete";

import { add_plans_to_bien } from "../../../../actions/bienActions";
const Input = styled("input")({
  display: "none",
});

const SectionPlan = (props) => {
  const [files, Setfiles] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(()=>{
console.log('dkhaaaaaaaaaaaaaaaaaaaaaaaal',props.index,props.item)
  },[])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const fileSelectedHandlersss = (e) => {
    console.log("e.target.files", e.target.files);
    Setfiles({ imgCollection: e.target.files });
  };

  const deleteimg = (item) => {
    console.log("item", item);
    let photosupdated = props.bien.plans.filter((id) => id !== item);
    const dataupdated = {
      ...props.bien,
      plans: photosupdated,
    };
    props.updatebien(dataupdated);
  };

  const uploadHandlers = (e) => {
    console.log(files.imgCollection, "files.imgCollection");
    e.preventDefault();
    var formData = new FormData();
    for (const key of Object.keys(files.imgCollection)) {
      formData.append("imgCollection", files.imgCollection[key]);
    }
    formData.append("id", props.bien._id);
    add_plans_to_bien(props.bien._id, formData).then((res) => {
      console.log(res);
      props.updateplans();
    });
  };
  if (props.bien?.plans?.length <= 0 || props.bien?.plans == undefined) {
    return (
      <div class="containers">
        <h3>Aucun plan associé avec ce bien</h3>
        <div>
          sélectionner les plans à ajouter
          <label htmlFor="icons-button-file">
          <input
                      accept="pdf"
                      multiple
                      onChange={(e) => {
                        fileSelectedHandlersss(e);
                      }}
                      id="icons-button-file"
                      type="file"
                    />
            puis cliquer sur
            <Button
              onClick={uploadHandlers}
              style={{
                marginRight: "10px",
                backgroundColor: "#122659",
                width: "90px",
                fontSize: "10px",
                marginLeft: "10px",
              }}
              variant="contained"
            >
              Télécharger
            </Button>
          </label>
        </div>
      </div>
    );
  } else {
    return (
      <Grid
        container
        spacing={2}
        style={{ width: "100%", height: "fitt-content" }}
      >
     
          <Grid
            item
            xs={props?.big?12:4}
            md={props?.big?12:4}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ position: "relative" }}>
              {props?.item ? (
                <a
                  href={props.item}
                  target="_blank"
                  style={{ width: "100%", height: "100%" }}
                >
                  <Document
                    style={{ width: "100%" }}
                    file={props.item}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={{
                      workerSrc:
                        "//cdn.jsdelivr.net/npm/react-pdf@4.0.0-beta.5/dist/pdf.worker.entry.js",
                    }}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                </a>
              ) : (
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={props.item}
                  alt={props.item}
                />
              )}
{     props.from===2 &&        <button
               style={{zIndex:1,
                position: "absolute",
                top: "90%",
                
                transform: "translate(-50%, -50%)",
                "-ms-transform":"translate(-50%, -50%)",
                backgroundColor: "transparent",
                color: "#122659",
              
                border: "none",
                cursor: "pointer",
                borderRadius: "5px",
                height: "20%",
                width: "20%"
                }}
                onClick={() => deleteimg(props.item)}
              >
           
                  
                  <DeleteIcon />
               
              </button>}
              
            </div>
          </Grid>
     

      </Grid>
    );
  }
};
export default SectionPlan;
