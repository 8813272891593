let APIServerUrl;
if (process.env.REACT_APP_ENVIRONNEMENT === "production") {
  APIServerUrl = "https://application.wealzy.com";
} else if (process.env.REACT_APP_ENVIRONNEMENT === "preproduction") {
  APIServerUrl = "https://" + "api.test.application.wealzy.com";
} else if (process.env.REACT_APP_ENVIRONNEMENT === "test") {
  APIServerUrl = "http://" + window.location.hostname + ":" + 5100;
} else if (process.env.REACT_APP_ENVIRONNEMENT === "devlocal") {
  APIServerUrl = "http://" + window.location.hostname + ":" + 5200;
} else if (process.env.REACT_APP_ENVIRONNEMENT === "development") {
  APIServerUrl = "https://" + window.location.hostname;
} else if (process.env.REACT_APP_ENVIRONNEMENT === "lab") {
  APIServerUrl = "https://" + "api.lab.application.wealzy.com";
  //APIServerUrl ="http://" + window.location.hostname + ":" + 5200
}

let APIFrontUrl;
if (process.env.REACT_APP_ENVIRONNEMENT === "production") {
  APIFrontUrl = "http://" + window.location.hostname + ":" + 80;
} else if (process.env.REACT_APP_ENVIRONNEMENT === "preproduction") {
  APIFrontUrl = "http://" + window.location.hostname + ":" + 82;
} else if (process.env.REACT_APP_ENVIRONNEMENT === "test") {
  APIFrontUrl = "http://" + window.location.hostname + ":" + 80;
} else if (process.env.REACT_APP_ENVIRONNEMENT === "development") {
  APIFrontUrl = "http://" + window.location.hostname + ":" + 80;
} else if (process.env.REACT_APP_ENVIRONNEMENT === "devlocal") {
  APIFrontUrl = "http://" + window.location.hostname + ":" + 80;
}

//console.log('APIServerUrl', APIServerUrl, process.env.REACT_APP_ENVIRONNEMENT)

let fileServerUrl;
if (process.env.REACT_APP_ENVIRONNEMENT === "production") {
  fileServerUrl = "https://fichier.prod.application.wealzy.com";
} else if (process.env.REACT_APP_ENVIRONNEMENT === "preproduction") {
  fileServerUrl = "https://" + "fichier.application.wealzy.com";
} else if (process.env.REACT_APP_ENVIRONNEMENT === "test") {
  fileServerUrl = "http://" + window.location.hostname + ":" + 8081;
} else if (process.env.REACT_APP_ENVIRONNEMENT === "development") {
  fileServerUrl = "http://" + window.location.hostname + ":" + 8083;
} else if (process.env.REACT_APP_ENVIRONNEMENT === "devlocal") {
  fileServerUrl = "http://" + window.location.hostname + ":" + 8083;
} else if (process.env.REACT_APP_ENVIRONNEMENT === "lab") {
  fileServerUrl = "https://fichier.prod.application.wealzy.com";
}

module.exports = {
  APIServerUrl,
  fileServerUrl,
  APIFrontUrl,
};
