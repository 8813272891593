import axios from 'axios';
import {
  GET_COMMUNE_BY_INSEE,
  GET_COMMUNE_FROM_COORD,
  SEARCH_COMMUNE_COORDINATES,
} from '../actions/types';
import { source } from './bienActions'
const { APIServerUrl } = require('../shared/values/environnement')

// Get commune by coordinates
export const getCommuneFromCoordinates = (x, y) => dispatch => {
  axios
    .get(APIServerUrl + '/api/commune/coordinates/' + x + '/' + y)
    .then(res => {
      dispatch({
        type: GET_COMMUNE_FROM_COORD,
        payload: res.data
      })
    })
    .catch(err => {
      dispatch({
        type: GET_COMMUNE_FROM_COORD,
        payload: { error: 'KO' }
      })
    })
}

// Get commune by Insee
export const getCommuneByInsee = data => dispatch => {
  if([0, 1, 2, 3, 4,10].includes(data.role)) {
    if (data.codeInsee !== undefined) {
      return axios
        .get(APIServerUrl + '/api/commune/' + data.codeInsee)
        .then(res => {
          dispatch({
            type: GET_COMMUNE_BY_INSEE,
            payload: res.data
          })
          return res.data
        })
        .catch(res => {
          console.log("erreur ", res);
        })
    }
  } else {
    return false;
  }
}

export const getCodeByPostal =(codePostal)=>{
  return axios
      .get("https://geo.api.gouv.fr/communes?codePostal=" + codePostal)
      .then(res => {
        return res.data
      })
}

// Get commune by Insee
export const getCommuneByInseeNoRedux = (codeInsee) => {
  if (codeInsee !== undefined) {
    return axios
      .get(APIServerUrl + '/api/commune/' + codeInsee)
      .then(res => {
        return res.data
      })
      .catch(res => {
        console.log("erreur ", res);
      })
  }
}

export const getCommuneByInseeC = (codeInsee) => {
  if (codeInsee !== undefined) {
    return axios
      .get(APIServerUrl + '/api/commune/' + codeInsee)
   
  }
}

// Get Liste Commune from coordinates and radius
export const getCommuneAround = (lat, lon, rayon, connectedUserRole) => {
  source.cancel('Opérations annulées par getCommuneAround');
  if([0, 1, 2, 3, 4].includes(connectedUserRole)){
    return axios
    .get(APIServerUrl + '/api/commune/' + lat + '/' + lon + '/' + rayon)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    })
  }
}

// Get Liste Commune from text
export const getCommuneFromText = (query) => {
  return axios
    .get(APIServerUrl + '/api/commune/search-by-text/' + query)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    })
}

// Get Liste de toutes les Communes
export const getAllCommune = () => {
  return axios
    .get(APIServerUrl + '/api/commune/search/all')
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    })
}

// Get commune coordinates
export const getCommuneCoordinates = (codeName, nomCommune) => dispatch => {
  const url = 'https://api-adresse.data.gouv.fr/search/?q=' + codeName + "+" + nomCommune + "&type=municipality"
  const header = {
    'Content-Type': 'text/plain',
    'ACCEPT': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3',
    'ACCEPT_ENCODING': 'gzip, deflate, br',
    'ACCEPT_LANGUAGE': 'fr-FR,fr;q=0.9,en-US;q=0.8,en;q=0.7',
    'USER_AGENT': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/76.0.3809.132 Safari/537.36'
  }

  axios
    .get(url, {
      headers: header
    })
    .then(res => {
      dispatch({
        type: SEARCH_COMMUNE_COORDINATES,
        payload: res
      });
    })
    .catch(err =>
      dispatch({
        type: SEARCH_COMMUNE_COORDINATES,
        payload: { error: err }
      })
    );
};