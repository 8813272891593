import axios from "axios";
import setAuthToken from "../shared/functions/setAuthToken";
import jwt_decode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import { browserHistory } from "react-router";
import socketIOClient from "socket.io-client";
import { socket } from "../App";
import {
  GET_ERRORS,
  GET_ALL_CLIENT_GESTIONNAIRE,
  GET_ALL_GESTIONNAIRE_ADMIN,
  GET_GESTIONNAIRE,
  GET_CREATE_OR_UPDATE_USER_ERRORS,
  SET_CURRENT_USER,
  CREATE_USER,
  REINIT_REGISTER,
  GET_ALL_USERS,
  DELETE_USER,
  ALERT_ACTIVER,
  ALERT_DESACTIVER,
  UPDATE_USER,
  UPDATE_USER_PHOTO,
  UPDATE_IDSTRIPE,
  UPDATE_IDSUB,
  UPDATE_MAIL,
  RESET_USER,
  GET_USER,
  UPDATE_USER_COMPTE,
  UPDATE_USER_INFOS,
  UPDATE_USER_INFOS_ENTREPRISE,
  UPDATE_USER_CRITERE_RECHERCHE,
  UPDATE_USER_MANDAT,
  UPDATE_DATA_USER_NOT_YET,
  UPDATE_USER_ERRORS,
  DELETE_BIEN_WALLET,
  REINIT_USER_MISE_A_JOUR,
  UPDATE_USER_COLORS,
  LOGOUT_USER,
} from "./types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import useSocket from "../components/auth/socketConnect";
import { source } from "./bienActions";
import useHotjar from "react-use-hotjar";
const { APIServerUrl, APIFrontUrl } = require("../shared/values/environnement");
const { detect } = require("detect-browser");

// Get All Users
export const getAllUsers = (role) => (dispatch) => {
  axios
    .get(APIServerUrl + "/api/users/" + role)
    .then((res) => {
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ALL_USERS,
        payload: err,
      })
    );
};

export const getAllUser = () => {
  return axios.get(APIServerUrl + "/api/users/liste/all");
};

export const getListeClientGestionnairestat = (data) => {
  return axios.get(
    APIServerUrl + "/api/users/gestionnaire/liste-clients/" + data.idUser
  );
};

export const AddUser = (data) => {
  return axios.post(APIServerUrl + "/api/users/addUser", data);
};
export const UpdateUser = (data) => {
  return axios.put(APIServerUrl + "/api/users/updateUser", data);
};

// Récupérer tous les clients d'un gestionnaire
export const getListeClientGestionnaire = (data) => (dispatch) => {
  if ([0, 1, 2, 3, 4, 10].includes(parseInt(data.role))) {
    return axios
      .get(
        APIServerUrl + "/api/critere/" + data.idUser
      )
      .then((res) => {
        if (data.jsonOnly === false) {
          dispatch({
            type: GET_ALL_CLIENT_GESTIONNAIRE,
            payload: res.data,
          });
        } else {
          return new Promise((resolve) => resolve(res.data));

          //return res.data
        }
      })
      .catch((err) => {
        if (data.jsonOnly === false) {
          dispatch({
            type: GET_ALL_CLIENT_GESTIONNAIRE,
            payload: err,
          });
        } else {
          console.log(("err", err));
        }
      });
  } else {
    return false;
  }
};

export const getListeClientGestionnaireJSON = async (data) => {
  if ([0, 1, 2, 3, 4, 10].includes(parseInt(data.role))) {
    return axios
      .get(
        APIServerUrl + "/api/users/gestionnaire/liste-clients/" + data.idUser
      )
      .then((res) => {
        return new Promise((resolve) => resolve(res.data));

        //return res.data
      })
      .catch((err) => {
        console.log(("err", err));
      });
  } else {
    return false;
  }
};

export const getjournalgestionnairebyadmin = (data) => {
  if ([0, 1].includes(parseInt(data.role))) {
    return axios.get(
      APIServerUrl + "/api/users/getuserconnectionstats/" + data.idUser
    );
  }
};

// Récupérer tous les gestionnaires d'un admin client
export const getListeGestionnaireAdmin = (data) => (dispatch) => {
  console.log('dataaaaaa',data)
  if ([0, 1].includes(parseInt(data.role))) {
    return axios
      .get(
        APIServerUrl +
          "/api/users/admin-client/liste-gestionnaire/" +
          data.idUser
      )
      .then((res) => {
        dispatch({
          type: GET_ALL_GESTIONNAIRE_ADMIN,
          payload: res.data,
        });
        return res.data;
      })
      .catch((err) =>
        dispatch({
          type: GET_ALL_GESTIONNAIRE_ADMIN,
          payload: err,
        })
      );
  } else {
    return false;
  }
};

// Récupérer le gestionnaire d'une client
export const getGestionnaire = (id_user) => (dispatch) => {
  return axios
    .get(APIServerUrl + "/api/users/gestionnaire/" + id_user)
    .then((res) => {
      dispatch({
        type: GET_GESTIONNAIRE,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) =>
      dispatch({
        type: GET_GESTIONNAIRE,
        payload: err,
      })
    );
};

// Delete User
export const deleteUser = (email) => {
  return axios.post(APIServerUrl + `/api/users/delete`, { email: email });
  // .then(res => {
  //   dispatch({
  //     type: DELETE_USER,
  //     payload: res.data
  //   })
  // })
  // .catch(err =>
  //   dispatch({
  //     type: DELETE_USER,
  //     payload: err
  //   })
  // )
};

export const Alert_activer = (email) => (dispatch) => {
  axios
    .post(APIServerUrl + `/api/users/Alert_activer`, { email: email })
    .then((res) => {
      dispatch({
        type: ALERT_ACTIVER,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: ALERT_ACTIVER,
        payload: err,
      })
    );
};
export const Alert_desactiver = (email) => (dispatch) => {
  axios
    .post(APIServerUrl + `/api/users/Alert_desactiver`, { email: email })
    .then((res) => {
      dispatch({
        type: ALERT_DESACTIVER,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: ALERT_DESACTIVER,
        payload: err,
      })
    );
};

export const setuserToUpdate = (user) => (dispatch) => {
  dispatch({
    type: GET_USER,
    payload: user,
  });
};

// Get User
export const getUserById = (_id) => (dispatch) => {
  source.cancel("Opérations annulées par getUserById");
  return axios
    .get(APIServerUrl + "/api/users/user/" + _id)
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const getUserByIdtoSet = (_id) => {
  source.cancel("Opérations annulées par getUserById");
  return axios.get(APIServerUrl + "/api/users/user/" + _id);
};

// Get User
export const getUserByIdJSON = (_id) => {
  return axios
    .get(APIServerUrl + "/api/users/user/" + _id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
export const getCritereByIdJSON = (_id) => {
  return axios
    .get(APIServerUrl + "/api/critere/user/id/" + _id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const getUserByMail = (email) => (dispatch) => {
  return axios
    .post(APIServerUrl + "/api/users/getUserByMail", { email })
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => console.log(err));
};

// Get User
export const resetUserData = () => {
  return {
    type: RESET_USER,
    payload: {},
  };
};

// Get Nom Prénom User
export const getNomPrenom = (_id) => {
  return axios
    .get(APIServerUrl + "/api/users/user/nom-prenom/" + _id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log("getNomPrenom", err));
};

// Get User - liste des biens de son portefeuille
export const getBienWalletUserById = (_id) => {
  source.cancel("Opérations annulées par getBienWalletUserById");

  return axios
    .get(APIServerUrl + "/api/users/user/" + _id + "/liste-bien")
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

// Register
export const registerUser = (userData) => (dispatch) => {
  console.log("dkhal", userData);
  return axios
    .post(APIServerUrl + "/api/users/register", userData)
    .then((res) => {
      // Confirmation de la création du user
      dispatch({
        type: CREATE_USER,
        payload: { statusRegisterUser: "True" },
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: GET_CREATE_OR_UPDATE_USER_ERRORS,
        payload: err.response.data,
      });
      return err.response.data;
    });
};

// Update
export const updateUser = (userData) => (dispatch) => {
  console.log("us", userData);
  axios
    .post(APIServerUrl + "/api/users/update/", userData)
    .then((res) => {
     
        if(res.status==200){
          window.location.href=window.location.origin+"/in/client/liste-clients"
        }
      // Confirmation de la création du user
      dispatch({
        type: UPDATE_USER,
        payload: userData,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_CREATE_OR_UPDATE_USER_ERRORS,
        payload: err.response.data,
      })
    );
};

export const updateEntreprisePhoto = (userData) => (dispatch) => {
  return dispatch({
    type: UPDATE_USER_PHOTO,
    payload: userData,
  });
};

// Update gestionnaire
export const updateGestionnaire = (data) => {
  return axios
    .post(APIServerUrl + "/api/users/update-gestionnaire", data)
    .then((res) => {
      return "OK";
    })
    .catch((err) => {
      return "KO";
    });
};

export const AddIDStripe = (_id, IDStripe) => (dispatch) => {
  return axios
    .post(APIServerUrl + "/api/users/IDStripe", { _id, IDStripe })
    .then((res) => {
      dispatch({
        type: UPDATE_IDSTRIPE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_IDSTRIPE,
        payload: err,
      });
    });
};

export const AddIDSub = (id, idsub) => (dispatch) => {
  return axios
    .post(APIServerUrl + "/api/users/idSub", { id, idsub })
    .then((res) => {
      dispatch({
        type: UPDATE_IDSUB,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_IDSUB,
        payload: err,
      });
    });
};

export const AddIDProduct = (id, idproduct) => {
  return axios
    .post(APIServerUrl + "/api/users/idproduct", { id, idproduct })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

// Delete Bien Client from the walette
export const deleteBienClient = (bienClient) => (dispatch) => {
  return axios
    .post(APIServerUrl + "/api/users/delete-wallet-bien", bienClient)
    .then((res) => {
      dispatch({
        type: DELETE_BIEN_WALLET,
        payload: bienClient,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

export const confirmMail = (id) => (dispatch) => {
  return axios
    .post(APIServerUrl + "/api/users/confirmMail", { id })
    .then((res) => {
      dispatch({
        type: UPDATE_MAIL,
        payload: res.data,
      });
      return res;
    })
    .catch((err) =>
      dispatch({
        type: UPDATE_MAIL,
        payload: err,
      })
    );
};

// Reinitialize Register
export const reinitializeRegister = () => {
  return {
    type: REINIT_REGISTER,
    payload: { statusRegisterUser: "False" },
  };
};

// Reinitialize Mise à jour
export const reinitializeMiseAJour = () => {
  return {
    type: REINIT_USER_MISE_A_JOUR,
    payload: 0,
  };
};

export const logout_user = () => (dispatch) => {
  return dispatch({ type: LOGOUT_USER });
};

// Login - Get User Token

function getUs(email) {
  return axios
    .post(APIServerUrl + "/api/users/getUserByMail", { email: email })
    .then((res) => {
    
      localStorage.setItem("bn1", res.data[0].browserName);
      localStorage.setItem("bv1", res.data[0].browserVersion);

      localStorage.setItem("bos1", res.data[0].browserOs);
      localStorage.setItem("ip1", res.data[0].ipAddress);
    });
}

export const loginUser = (userData) => (dispatch) => {
  // getUs(userData.email)
  //   var IPv4=""
  //   axios.get('https://geolocation-db.com/json/')
  //   .then(res =>{
  //     IPv4=res.data.IPv4
  //     console.log("aaaaa")
  //     console.log(res)
  //    //cbon
  //   })

  //   console.log("da",userData)
  // localStorage.setItem('Email',userData.email)
  // localStorage.setItem('Pass',userData.password)
  //  userData= {
  //   ...userData,
  //   ipAddress:localStorage.getItem('ip'),
  //   browserName:localStorage.getItem('bn'),
  //   browserOs:localStorage.getItem('bos'),
  //   browserVersion:localStorage.getItem('bv'),
  // }
  // const socket = socketIOClient.connect(APIServerUrl)
  // //const socket= useContext(SocketContext)
  // console.log("je",userData)
  //         socket.emit('connected',userData.email)
  //         console.log('aaaverif')
  //        socket.emit('verif',userData.email)
  //        socket.on('userverif',res=>{
  //          console.log("aaverif",res)
  //          if((localStorage.getItem('ip')==localStorage.getItem('ip1'))&&(localStorage.getItem('bos')==localStorage.getItem('bos1'))){
  //            console.log('falseee')
  //           axios
  //      .post(APIServerUrl + '/api/users/login', userData)
  //      .then(res => {
  //        // Save to localStorage
  //       const { token } = res.data
  // //       // Set token to ls
  // // console.log(res)
  //        localStorage.setItem('jwtToken', token)

  // //       // Set token to Auth header
  //        setAuthToken(token)

  // //       // Decode token to get user data
  //        const decoded = jwt_decode(token)
  // //       console.log(setCurrentUser(decoded).payload.email)
  // //       // Set current user
  //        localStorage.setItem('Prenom',setCurrentUser(decoded).payload.prenom)
  //        localStorage.setItem('Nom',setCurrentUser(decoded).payload.nom)
  //        localStorage.setItem('Email',setCurrentUser(decoded).payload.email)

  // //       //window.location.href = "http://localhost:3000/in/annonce/liste-annonce"

  //        dispatch(setCurrentUser(decoded))
  //        dispatch({
  //          type: GET_ERRORS,
  //          payload: {}
  //        })
  //      })
  //      .catch(err => {
  //        console.log(err)
  //        dispatch({
  //          type: GET_ERRORS,
  //          payload: err.response.data
  //        })
  //      })
  //           }
  //          else
  //         if(res){
  //           console.log(APIFrontUrl)
  //           window.location.href = APIFrontUrl+":3000/verif/"+userData.email;
  //         }else{
  //           console.log('falseee')
  //           axios
  //     .post(APIServerUrl + '/api/users/login', userData)
  //     .then(res => {
  //       // Save to localStorage
  //       const { token } = res.data
  //       // Set token to ls
  // console.log(res)
  //       localStorage.setItem('jwtToken', token)

  //       // Set token to Auth header
  //       setAuthToken(token)

  //       // Decode token to get user data
  //       const decoded = jwt_decode(token)
  //       console.log(setCurrentUser(decoded).payload.email)
  //       // Set current user
  //       localStorage.setItem('Prenom',setCurrentUser(decoded).payload.prenom)
  //       localStorage.setItem('Nom',setCurrentUser(decoded).payload.nom)
  //       localStorage.setItem('Email',setCurrentUser(decoded).payload.email)

  //       window.location.href = APIFrontUrl+"/in/annonce/liste-annonce"

  //       dispatch(setCurrentUser(decoded))
  //       dispatch({
  //         type: GET_ERRORS,
  //         payload: {}
  //       })
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       dispatch({
  //         type: GET_ERRORS,
  //         payload: err.response.data
  //       })
  //     })
  //         }

  //        })
  //         socket.on('typing',(user,id)=>
  //         {

  //          console.log("Event",user)
  //         // logout_user()
  //         })

  //   if(1==0){

  axios
    .post(APIServerUrl + "/api/users/login", userData)
    .then((res) => {
      const { token } = res.data;
      setAuthToken(token);

      // Decode token to get user data
      const decoded = jwt_decode(token);
      var now = new Date();
      // now.setDate(now.getDate()+14)
      now.setDate(now.getDate());
     

      localStorage.setItem("id", setCurrentUser(decoded).payload.id);
      localStorage.setItem("Email", setCurrentUser(decoded).payload.email);
      now = now.toISOString();
      

      if (
        now.toString().substr(0, 10) >= decoded.dateVal.toString().substr(0, 10)
      ) {
        //  localStorage.setItem('jwtToken', token)
        //  localStorage.setItem('Prenom',setCurrentUser(decoded).payload.prenom)
        //  localStorage.setItem('Nom',setCurrentUser(decoded).payload.nom)
        //  localStorage.setItem('Email',setCurrentUser(decoded).payload.email)
        
        localStorage.setItem("role", decoded.role);
        //  dispatch(setCurrentUser(decoded))
        window.location.href = window.location.origin + "/validite ";
      } else {
        if(localStorage.getItem("linked")){
          console.log("dkhal");
          localStorage.setItem("jwtToken", token);
  
          // Set token to Auth header
  
          // Set current user
          localStorage.setItem("Prenom", setCurrentUser(decoded).payload.prenom);
          localStorage.setItem("Nom", setCurrentUser(decoded).payload.nom);
          localStorage.setItem("Email", setCurrentUser(decoded).payload.email);
  
          dispatch(setCurrentUser(decoded));
          window.location.href=localStorage.getItem("linked")
        }
       else {        // Save to localStorage

        // Set token to ls
        console.log("connecteeee");
        localStorage.setItem("jwtToken", token);

        // Set token to Auth header

        // Set current user
        localStorage.setItem("Prenom", setCurrentUser(decoded).payload.prenom);
        localStorage.setItem("Nom", setCurrentUser(decoded).payload.nom);
        localStorage.setItem("Email", setCurrentUser(decoded).payload.email);

        dispatch(setCurrentUser(decoded));

        dispatch({
          type: GET_ERRORS,
          payload: {},
        });
      }}
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

//}

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log User Out
export const logoutUser = () => (dispatch) => {
  console.log("a");
  const data = {
    email: localStorage.getItem("Email"),
  };
  console.log("aa", data.email);
  axios.post(APIServerUrl + "/api/users/logout", data).then((res) => {
    console.log("aaa");
    console.log("logout");
  });
  // Remove token from loca lstorage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("request");
  localStorage.removeItem("req");
  // localStorage.removeItem('Email')
  localStorage.removeItem("Prenom");
  localStorage.removeItem("Nom");
  localStorage.removeItem("idSocket");
  localStorage.removeItem("residentiel");
  localStorage.removeItem("localisation");
  localStorage.removeItem("client");
  localStorage.removeItem("chercheL");
  localStorage.removeItem("annonceD");
  localStorage.removeItem("modelR");
  localStorage.removeItem("annoncePdf");
  localStorage.removeItem("btnadd");
  localStorage.removeItem("nav");
  localStorage.clear();
  // Remove auth header for future requests
  setAuthToken(false);

  // Set the current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch({
    type: GET_ERRORS,
    payload: {},
  });
};
export const logoutSession = () => (dispatch) => {
  // Remove token from loca lstorage
  localStorage.removeItem("jwtToken");
  // localStorage.removeItem('Email')
  localStorage.removeItem("Prenom");
  localStorage.removeItem("Nom");
  localStorage.removeItem("idSocket");
  // Remove auth header for future requests
  setAuthToken(false);

  // Set the current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch({
    type: GET_ERRORS,
    payload: {},
  });
};

// Update Compte User
export const updateUserCompte = (compteUser) => {
  console.log("compteUser", compteUser);
  return {
    type: UPDATE_USER_COMPTE,
    payload: compteUser,
  };
};

// Update Infos User
export const updateUserInfos = (infosUser) => {
  return {
    type: UPDATE_USER_INFOS,
    payload: infosUser,
  };
};

// Update Infos User
export const updateUserInfosEntreprise = (infosUser) => {
  return {
    type: UPDATE_USER_INFOS_ENTREPRISE,
    payload: infosUser,
  };
};

// Update Mandat de recherche
export const updateUserCritereRecherche = (critereUser) => {
  return {
    type: UPDATE_USER_CRITERE_RECHERCHE,
    payload: critereUser,
  };
};

// Update Mandat de recherche
export const updateUserMandat = (mandatUser) => {
  return {
    type: UPDATE_USER_MANDAT,
    payload: mandatUser,
  };
};

// Update Infos User
export const updateUserErrors = (userErrors) => {
  return {
    type: UPDATE_USER_ERRORS,
    payload: userErrors,
  };
};

// Indiquer que le bien n'est pas sauvegardé
export const updateDataUserNotYet = (value) => {
  console.log("values", value);
  return {
    type: UPDATE_DATA_USER_NOT_YET,
    payload: value,
  };
};

export const checkEntry = (data) => {
  return axios
    .post(APIServerUrl + "/api/users/checkEntry", { data })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const autoSaveRegister = (data) => {
  return axios
    .post(APIServerUrl + "/api/users/autoSaveRegister", { data })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteuserprospect = (data) => {
  return axios
    .post(APIServerUrl + "/api/users/deleteuserprospect", { data })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const resetPassword = (data) => {
  return axios
    .post(APIServerUrl + "/api/users/resetPassword", { data })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getIdSub = (id) => {
  return axios
    .post(APIServerUrl + "/api/users/get-idSub", { id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const billing_details = (id, billing_details) => {
  return axios
    .post(APIServerUrl + "/api/users/billing_details", { id, billing_details })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getsuperadminstats = (id) => {
  return axios.get(APIServerUrl + "/api/users/getallstats/" + id);
};
export const getsuperadminallstats = (id) => {
  return axios.get(APIServerUrl + "/api/users/getallstatsusers/" + id);
};

export const getsuperadminstats_filtred = (id, startdate, endate) => {
  return axios.post(APIServerUrl + "/api/users/getallstats/" + id, {
    startdate: startdate,
    endate: endate,
  });
};

export const update_user_datevalidation = (id, datevalidation) => {
  return axios
    .post(APIServerUrl + "/api/pay/update_user_dateval", {
      id,
      datevalidation,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
