import {
  GET_ANNONCE_NON_INVESTISSEUR,
  GET_ANNONCE_WALLET,
  GET_BIEN_ALL,
  GET_DATA_BIEN,
  UPDATE_CRITERE_RECHERCHE,
  UPDATE_CRITERE_RECHERCHE_REQSTART,
  UPDATE_DATABIEN_FROM_WALLET,
  GET_LISTE_BIEN_FROM_WALLET,
  UPDATE_DATA_BIEN,
  ADD_BIEN,
  UPDATE_DATA_BIEN_NOT_YET,
  GET_BIEN_SEARCH,
  RESET_BIEN_SEARCH,
  RESET_BIEN_SEARCH_BRUT,
  RESET_BIEN_SEARCH_WALLET,
  RESET_DATA_BIEN,
  UPDATE_STATUT_QUALIFICATION,
  UPDATE_CLIENT_LIST_TO_UPDATE,
  GET_LISTE_BIEN_SIMILAIRE_VENTE,
  // STAT BIEN
  GET_COUNT_STATUT_QUALIFICATION,
  GET_COUNT_TYPE_BIEN,
  GET_COUNT_SOURCE,
  GET_COUNT_SOURCE_24,
  GET_COUNT_TODAY,
  GET_CONNEXION,
  //
  UPDATE_LISTE_BIEN_SIMILAIRE_VENTE,
  UPDATE_LISTE_BIEN_SIMILAIRE_LOCATION,
  // UPDATE CARACTERISTIQUE BIEN
  UPDATE_CARACTERISTIQUE_BIEN,
  UPDATE_CARACTERISTIQUE_BIEN_DESCRIPTION_BIEN,
  UPDATE_CARACTERISTIQUE_BIEN_SURFACE,
  UPDATE_CARACTERISTIQUE_BIEN_NBE_PIECE,
  UPDATE_CARACTERISTIQUE_BIEN_STATUT_QUALIFICATION,
  UPDATE_CARACTERISTIQUE_BIEN_TYPE_BIEN,

  // UPDATE PHOTOS BIEN
  UPDATE_PHOTO_BIEN,
  UPDATE_FAVORIS_PHOTO_BIEN,
  SEND_ANNONCE_A_QUALIFIER,
  GET_NOMBRE_ANNONCE,
  GET_NOMBRE_ANNONCE_BRUT,
  GET_NOMBRE_ANNONCE_WALLET,

  // UPDATE ADRESSE BIEN
  UPDATE_ADRESSE_BIEN,

  // UPDATE CLIENTS BIEN
  UPDATE_CLIENT_BIEN,

  // Update Prix Bien
  UPDATE_PRIX_BIEN_PRIX_BIEN_FAI,
  UPDATE_PRIX_BIEN_FRAIS_AGENCE,
  UPDATE_PRIX_BIEN_FRAIS_NOTAIRE,
  UPDATE_PRIX_BIEN_NEGOCIATION,
  UPDATE_PRIX_BIEN_COMMISSION_WEALZY,
  UPDATE_PRIX_BIEN_MONTANT_MEUBLE,
  UPDATE_PRIX_BIEN_AUTRES_FRAIS,
  UPDATE_PRIX_BIEN_MONTANT_TRAVAUX,
  UPDATE_PRIX_BIEN_PRIX_BIEN_HORS_FAI,
  UPDATE_PRIX_BIEN_PRIX_BIEN_COMPLET,
  UPDATE_PRIX_BIEN_PRIX_M2_VENTE_REEL,
  SAVE_CURRENT_LIST_BIEN,
  SAVE_CURRENT_PAGE,
  // Update Revenu Locatif Bien
  UPDATE_REVENU_LOCATIF_PRIX_LOC_NUE_MENS,
  UPDATE_REVENU_LOCATIF_PRIX_LOC_NUE_ANNU,
  UPDATE_REVENU_LOCATIF_PRIX_LOC_MEUBLEE_MENS,
  UPDATE_REVENU_LOCATIF_PRIX_LOC_MEUBLEE_ANNU,
  UPDATE_REVENU_LOCATIF_NBE_CHAMBRE,
  UPDATE_REVENU_LOCATIF_PRIX_COLOC_CHAMBRE_MENSUEL,
  UPDATE_REVENU_LOCATIF_PRIX_COLOC_MENSUEL,
  UPDATE_REVENU_LOCATIF_PRIX_COLOC_ANNUEL,
  UPDATE_REVENU_LOCATIF_PRIX_LOC_RETENUE_MENS,
  UPDATE_REVENU_LOCATIF_PRIX_LOC_RETENUE_ANNU,
  UPDATE_REVENU_LOCATIF_OPTION_LOCATION,
  UPDATE_REVENU_LOCATIF_ELIGIBLE_COLOCATION,
  GET_BIEN_SIMILAIRE_VENTE,
  GET_BIEN_SIMILAIRE_LOCATION,
  UPDATE_REVENU_LOCATIF_PRIX_M2,
  GET_BIEN_NOTAIRE,

  // Update Charges Bien
  UPDATE_CHARGES_BIEN_ENTRETIEN_COURANT,
  UPDATE_CHARGES_BIEN_CHARGES_COPROPRIETE,
  UPDATE_CHARGES_BIEN_ASSURANCE_PNO,
  UPDATE_CHARGES_BIEN_CHARGES_GESTION,
  UPDATE_CHARGES_BIEN_GARANTIE_IMPAYES,
  UPDATE_CHARGES_BIEN_TAXE_FONCIERE,
  UPDATE_CHARGES_BIEN_CHARGES_COMPLEMENTAIRES,
  UPDATE_CHARGES_BIEN_CHARGES_TOTAL_CHARGES,

  // UPDATE FINANCEMENT BIEN
  UPDATE_FINANCEMENT_BIEN_APPORT_PERSO,
  UPDATE_FINANCEMENT_BIEN_MONTANT_A_EMPRUNTER,
  UPDATE_FINANCEMENT_BIEN_NBE_MENSUALITE_REMBOURSEMENT,
  UPDATE_FINANCEMENT_BIEN_TAUX_EMPRUNT,
  UPDATE_FINANCEMENT_BIEN_TAUX_ASSURANCE,
  UPDATE_FINANCEMENT_BIEN_FRAIS_GARANTIES_CAUTION,
  UPDATE_FINANCEMENT_BIEN_FRAIS_COURTAGE,
  UPDATE_FINANCEMENT_BIEN_FRAIS_DOSSIER_FINANCEMENT,
  UPDATE_FINANCEMENT_BIEN_MENSUALITE_REMBOURSEMENT_ASSURANCE,
  UPDATE_FINANCEMENT_BIEN_MENSUALITE_REMBOURSEMENT_HORS_ASSURANCE,
  UPDATE_FINANCEMENT_BIEN_MENSUALITE_REMBOURSEMENT,
  UPDATE_FINANCEMENT_BIEN_TABLEAU_AMORTISSEMENT_PRET,
  UPDATE_FINANCEMENT_BIEN_TABLEAU_CAPITAL_REMBOURSE,
  UPDATE_FINANCEMENT_BIEN_TABLEAU_REVENU_AVANT_TRAVAUX,
  UPDATE_CASH_FLOW_MOYEN,
  // UPDATE FISCALITE BIEN
  UPDATE_FISCALITE_BIEN_OPTION_FISCALE,
  UPDATE_FISCALITE_BIEN_REVENU_FONCIER_ANNUEL,
  UPDATE_FISCALITE_BIEN_TABLEAU_CHARGE_INTERET,
  UPDATE_FISCALITE_BIEN_CHARGE_INTERET_MOY_5ANS,
  UPDATE_FISCALITE_BIEN_NBE_ANNEE_DEFICIT_FONCIER,
  UPDATE_FISCALITE_BIEN_MONTANT_AMORTISSEMENT_BIEN_ANNUEL,
  UPDATE_FISCALITE_BIEN_TAB_CHARGES_INTERETS_ANNUEL,
  UPDATE_FISCALITE_BIEN_REVENU_FONCIER_ANNUEL_GLOBAL,
  UPDATE_RENDEMENT_PV,
  UPDATE_RENDEMENT_BRUT,
  UPDATE_RENDEMENT_NET,
  BIEN_CONSULTE,
  SAVE_CURRENT_PAGE_RES,
} from "../actions/types";

const initialState = {
  BienConsulte: false,
  ListeAnnoncesNonInv: [],
  ListeAnnoncesWallet: [],
  listeBiensSimilairesLocations: [],
  listeBiensSimilairesVente: [],
  listeBien: [],
  Page: 1,
  PageRes: 1,
  critereRecherche: {
    reqStart: 0,
  },
  cash_flow: 0,
  nbConnexion: [],
  capital_rembourse: [],
  revenu_avant_travaux: [],
  bien: {
    qualification_robot: {
      vente: {},
      rendement: {},
      charge: {},
      financement: {},
      prix: {},
      location: {},
      fiscalite: {},
    },
  },
};

export default function (state = initialState, action) {
  console.log(action, "nbe_chambre");

  switch (action.type) {
    case GET_BIEN_ALL:
      return {
        ...state,
        listeBien: action.payload,
      };
    case BIEN_CONSULTE:
      return {
        ...state,
        BienConsulte: action.payload,
      };
    case SAVE_CURRENT_PAGE:
      return {
        ...state,
        Page: action.payload,
      };
    case SAVE_CURRENT_PAGE_RES:
      return {
        ...state,
        PageRes: action.payload,
      };
    case GET_LISTE_BIEN_SIMILAIRE_VENTE:
      return {
        ...state,
        listeBiensSimilairesVente: action.payload,
      };

    case GET_ANNONCE_NON_INVESTISSEUR:
      return {
        ...state,
        ListeAnnoncesNonInv: state.ListeAnnoncesNonInv.concat(action.payload),
      };
    case GET_ANNONCE_WALLET:
      return {
        ...state,
        ListeAnnoncesWallet: state.ListeAnnoncesWallet.concat(action.payload),
      };
    case UPDATE_LISTE_BIEN_SIMILAIRE_LOCATION:
      return {
        ...state,
        listeBiensSimilairesLocation: action.payload,
      };
    case UPDATE_LISTE_BIEN_SIMILAIRE_VENTE:
      return {
        ...state,
        listeBiensSimilairesVente: action.payload,
      };

    case GET_BIEN_SEARCH:
      return {
        ...state,
        listeBien: state.listeBien.concat(action.payload),
      };
    case GET_NOMBRE_ANNONCE:
      return {
        ...state,
        nbeListeBien: action.payload,
      };
    case GET_NOMBRE_ANNONCE_BRUT:
      return {
        ...state,
        nbeListeBienBrut: action.payload,
      };
    case GET_NOMBRE_ANNONCE_WALLET:
      return {
        ...state,
        nbeListeBienWallet: action.payload,
      };
    case UPDATE_CRITERE_RECHERCHE:
      return {
        ...state,
        critereRecherche: {
          ...state.critereRecherche,
          idAnnonceFilter: action.payload.idAnnonceFilter,
          departementFilter: action.payload.departementFilter,
          communeFilterDisplay: action.payload.communeFilterDisplay,
          communeFilter: action.payload.communeFilter,
          typeBienFilter: action.payload.typeBienFilter,
          typeOfferFilter: action.payload.typeOfferFilter,
          statutQualificationFilter: action.payload.statutQualificationFilter,
          budgetFilter: action.payload.budgetFilter,
          rendementBrutFilter: action.payload.rendementBrutFilter,
          plusValueFilter: action.payload.plusValueFilter,
          surfaceFilter: action.payload.surfaceFilter,
          nbePieceFilter: action.payload.nbePieceFilter,
          clientSelection: action.payload.clientSelection,
          typeOffer: action.payload.typeOffer,
        },
      };
    case UPDATE_CRITERE_RECHERCHE_REQSTART:
      return {
        ...state,
        critereRecherche: {
          ...state.critereRecherche,
          reqStart: action.payload,
        },
      };
    case GET_COUNT_TODAY:
      return {
        ...state,
        countToday: action.payload,
      };
    case GET_COUNT_TYPE_BIEN:
      return {
        ...state,
        countByTypeBien: action.payload,
      };
    case GET_CONNEXION:
      return {
        ...state,
        nbConnexion: action.payload,
      };
    case GET_COUNT_SOURCE:
      return {
        ...state,
        countBySource: action.payload,
      };
    case GET_COUNT_SOURCE_24:
      return {
        ...state,
        countBySource24: action.payload,
      };
    case GET_COUNT_STATUT_QUALIFICATION:
      return {
        ...state,
        countByStatutQualification: action.payload,
      };
    case RESET_BIEN_SEARCH:
      return {
        ...state,
        listeBien: [],
        nbeListeBien: undefined,
      };

    case RESET_BIEN_SEARCH_BRUT: {
      return {
        ...state,
        ListeAnnoncesNonInv: [],
        nbeListeBienBrut: undefined,
      };
    }
    case RESET_BIEN_SEARCH_WALLET: {
      return {
        ...state,
        ListeAnnoncesWallet: [],
        nbeListeBienWallet: undefined,
      };
    }
    case UPDATE_STATUT_QUALIFICATION:
      return {
        ...state,
      };
    case GET_DATA_BIEN:
      return {
        ...state,
        bien: action.payload,
        mise_a_jour: 1,
      };
    case RESET_DATA_BIEN:
      return {
        ...state,
        bien: action.payload,
        mise_a_jour: 0,
      };
    case UPDATE_DATA_BIEN:
      return {
        ...state,
        mise_a_jour: 1,
      };
    case UPDATE_DATABIEN_FROM_WALLET:
      return {
        ...state,
        bien: action.payload,
        mise_a_jour: 1,
      };
    case GET_LISTE_BIEN_FROM_WALLET:
      return {
        ...state,
        wallet: action.payload,
      };
    case ADD_BIEN:
      return {
        ...state,
        bien: action.payload,
        mise_a_jour: 1,
      };
    case UPDATE_DATA_BIEN_NOT_YET:
      return {
        ...state,
        mise_a_jour: 0,
      };
    case UPDATE_CARACTERISTIQUE_BIEN_SURFACE:
      return {
        ...state,
        bien: {
          ...state.bien,
          surface: parseInt(action.payload, 10),
        },
      };
    case UPDATE_CARACTERISTIQUE_BIEN_NBE_PIECE:
      return {
        ...state,
        bien: {
          ...state.bien,
          nbe_piece: parseInt(action.payload, 10),
        },
      };
    case UPDATE_CARACTERISTIQUE_BIEN_DESCRIPTION_BIEN:
      return {
        ...state,
        bien: {
          ...state.bien,
          description_bien: action.payload,
        },
      };
    case UPDATE_CARACTERISTIQUE_BIEN:
      if (action.payload.name === "statut_qualification") {
        return {
          ...state,
          bien: {
            ...state.bien,
            qualification_robot: {
              ...state.bien.qualification_robot,
              statut_qualification: action.payload,
            },
          },
        };
      } else {
        return {
          ...state,
          bien: {
            ...state.bien,
            [action.payload.name]:
              action.payload.name === "surface" ||
              action.payload.name === "nbe_piece"
                ? parseInt(action.payload.value, 10)
                : action.payload.value,
          },
        };
      }

    case UPDATE_CARACTERISTIQUE_BIEN_TYPE_BIEN:
      return {
        ...state,
        bien: {
          ...state.bien,
          type_bien_corrige: action.payload,
        },
      };
    case UPDATE_CARACTERISTIQUE_BIEN_STATUT_QUALIFICATION:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            statut_qualification: action.payload,
          },
        },
      };
    case UPDATE_CLIENT_BIEN:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            clients: action.payload,
          },
        },
      };
    case UPDATE_CLIENT_LIST_TO_UPDATE:
      return {
        ...state,
        liste_client_to_update: action.payload,
      };
    case UPDATE_PHOTO_BIEN:
      return {
        ...state,
        bien: {
          ...state.bien,
          url_photo: action.payload,
        },
      };
    case UPDATE_FAVORIS_PHOTO_BIEN:
      return {
        ...state,
        bien: {
          ...state.bien,
          favoris_photo: action.payload,
        },
      };
    case UPDATE_ADRESSE_BIEN:
      return {
        ...state,
        bien: {
          ...state.bien,
          longitude: action.payload.longitude,
          latitude: action.payload.latitude,
          code_insee: action.payload.code_insee,
          code_postal: action.payload.code_postal,
          code_departement: action.payload.code_departement,
          nom_commune: action.payload.nom_commune,
          nom_iris: action.payload.nom_iris,
          code_iris: action.payload.code_iris,
          adresse: action.payload.adresse,
        },
      };
    case UPDATE_PRIX_BIEN_FRAIS_AGENCE:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            prix: {
              ...state.bien.qualification_robot.prix,
              frais_agence: action.payload,
            },
          },
        },
      };
    case UPDATE_PRIX_BIEN_FRAIS_NOTAIRE:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            prix: {
              ...state.bien.qualification_robot.prix,
              frais_notaire: action.payload,
            },
          },
        },
      };
    case UPDATE_PRIX_BIEN_MONTANT_MEUBLE:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            prix: {
              ...state.bien.qualification_robot.prix,
              montant_meuble: action.payload,
            },
          },
        },
      };
    case UPDATE_PRIX_BIEN_AUTRES_FRAIS:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            prix: {
              ...state.bien.qualification_robot.prix,
              autres_frais: action.payload,
            },
          },
        },
      };
    case UPDATE_PRIX_BIEN_MONTANT_TRAVAUX:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            prix: {
              ...state.bien.qualification_robot.prix,
              montant_travaux: action.payload,
            },
          },
        },
      };
    case UPDATE_PRIX_BIEN_NEGOCIATION:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            prix: {
              ...state.bien.qualification_robot.prix,
              negociation: action.payload,
            },
          },
        },
      };
    case UPDATE_PRIX_BIEN_PRIX_BIEN_COMPLET:
      console.log("payload", action);
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            prix: {
              ...state.bien.qualification_robot.prix,
              prix_bien_complet: action.payload,
            },
          },
        },
      };
    case UPDATE_PRIX_BIEN_PRIX_M2_VENTE_REEL:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            vente: {
              ...state.bien.qualification_robot.vente,
              prix_m2_vente_reel: action.payload,
            },
          },
        },
      };
    case UPDATE_PRIX_BIEN_PRIX_BIEN_FAI:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            prix: {
              ...state.bien.qualification_robot.prix,
              prix_bien_fai: action.payload,
            },
          },
        },
      };
    case UPDATE_PRIX_BIEN_PRIX_BIEN_HORS_FAI:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            prix: {
              ...state.bien.qualification_robot.prix,
              prix_bien_hors_fai: action.payload,
            },
          },
        },
      };
    case UPDATE_PRIX_BIEN_COMMISSION_WEALZY:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            prix: {
              ...state.bien.qualification_robot.prix,
              commission_wealzy: action.payload,
            },
          },
        },
      };
    case UPDATE_REVENU_LOCATIF_PRIX_LOC_NUE_MENS:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            location: {
              ...state.bien.qualification_robot.location,
              prix_location_nue_mensuel: action.payload,
            },
          },
        },
      };
    case UPDATE_REVENU_LOCATIF_PRIX_LOC_NUE_ANNU:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            location: {
              ...state.bien.qualification_robot.location,
              prix_location_nue_annuel: action.payload,
            },
          },
        },
      };
    case UPDATE_REVENU_LOCATIF_PRIX_LOC_MEUBLEE_MENS:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            location: {
              ...state.bien.qualification_robot.location,
              prix_location_meublee_mensuel: action.payload,
            },
          },
        },
      };
    case UPDATE_REVENU_LOCATIF_PRIX_LOC_MEUBLEE_ANNU:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            location: {
              ...state.bien.qualification_robot.location,
              prix_location_meublee_annuel: action.payload,
            },
          },
        },
      };
    case UPDATE_REVENU_LOCATIF_NBE_CHAMBRE:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            location: {
              ...state.bien.qualification_robot.location,
              nbeChambre: action.payload,
            },
          },
        },
      };
    case UPDATE_REVENU_LOCATIF_ELIGIBLE_COLOCATION:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            location: {
              ...state.bien.qualification_robot.location,
              eligible_colocation: action.payload,
            },
          },
        },
      };
    case UPDATE_REVENU_LOCATIF_PRIX_COLOC_CHAMBRE_MENSUEL:
      console.log("iciyo");
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            location: {
              ...state.bien.qualification_robot.location,
              prix_colocation_chambre_mensuel: action.payload,
            },
          },
        },
      };

    case UPDATE_REVENU_LOCATIF_PRIX_M2:
      console.log("iciyo");
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            location: {
              ...state.bien.qualification_robot.location,
              prix_m2_location_moyen: action.payload,
            },
          },
        },
      };
    case UPDATE_RENDEMENT_BRUT:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            rendement: {
              ...state.bien.qualification_robot.rendement,
              rendement_brut_retenue: action.payload,
            },
          },
        },
      };
    case UPDATE_RENDEMENT_NET:
      console.log("action.payload", action.payload);
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            rendement: {
              ...state.bien.qualification_robot.rendement,
              rendement_net_retenue: action.payload,
            },
          },
        },
      };
    case UPDATE_REVENU_LOCATIF_PRIX_COLOC_MENSUEL:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            location: {
              ...state.bien.qualification_robot.location,
              prix_colocation_mensuel: action.payload,
            },
          },
        },
      };
    case UPDATE_REVENU_LOCATIF_PRIX_COLOC_ANNUEL:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            location: {
              ...state.bien.qualification_robot.location,
              prix_colocation_annuel: action.payload,
            },
          },
        },
      };
    case UPDATE_REVENU_LOCATIF_PRIX_LOC_RETENUE_MENS:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            location: {
              ...state.bien.qualification_robot.location,
              prix_location_retenue_mensuel: action.payload,
            },
          },
        },
      };
    case UPDATE_REVENU_LOCATIF_PRIX_LOC_RETENUE_ANNU:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            location: {
              ...state.bien.qualification_robot.location,
              prix_location_retenue_annuel: action.payload,
            },
          },
        },
      };
    case UPDATE_REVENU_LOCATIF_OPTION_LOCATION:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            location: {
              ...state.bien.qualification_robot.location,
              option_location: action.payload,
            },
          },
        },
      };
    case UPDATE_CHARGES_BIEN_TAXE_FONCIERE:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            charge: {
              ...state.bien.qualification_robot.charge,
              taxe_fonciere: action.payload,
            },
          },
        },
      };
    case UPDATE_CHARGES_BIEN_GARANTIE_IMPAYES:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            charge: {
              ...state.bien.qualification_robot.charge,
              garantie_impayes: action.payload,
            },
          },
        },
      };
    case UPDATE_CHARGES_BIEN_CHARGES_GESTION:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            charge: {
              ...state.bien.qualification_robot.charge,
              charges_gestion: action.payload,
            },
          },
        },
      };
    case UPDATE_CHARGES_BIEN_ASSURANCE_PNO:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            charge: {
              ...state.bien.qualification_robot.charge,
              assurance_pno: action.payload,
            },
          },
        },
      };
    case UPDATE_CHARGES_BIEN_CHARGES_COPROPRIETE:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            charge: {
              ...state.bien.qualification_robot.charge,
              charges_copropriete: action.payload,
            },
          },
        },
      };
    case UPDATE_CHARGES_BIEN_CHARGES_COMPLEMENTAIRES:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            charge: {
              ...state.bien.qualification_robot.charge,
              charges_complementaires: action.payload,
            },
          },
        },
      };
    case UPDATE_CHARGES_BIEN_CHARGES_TOTAL_CHARGES:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            charge: {
              ...state.bien.qualification_robot.charge,
              total_charges: action.payload,
            },
          },
        },
      };
    case UPDATE_CHARGES_BIEN_ENTRETIEN_COURANT:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            charge: {
              ...state.bien.qualification_robot.charge,
              entretien_courant: action.payload,
            },
          },
        },
      };
    case UPDATE_FINANCEMENT_BIEN_APPORT_PERSO:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            financement: {
              ...state.bien.qualification_robot.financement,
              apport_personnel: action.payload,
            },
          },
        },
      };
    case UPDATE_FINANCEMENT_BIEN_MONTANT_A_EMPRUNTER:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            financement: {
              ...state.bien.qualification_robot.financement,
              montant_a_emprunter: action.payload,
            },
          },
        },
      };
    case UPDATE_FINANCEMENT_BIEN_NBE_MENSUALITE_REMBOURSEMENT:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            financement: {
              ...state.bien.qualification_robot.financement,
              nbe_mensualite_remboursement: action.payload,
            },
          },
        },
      };
    case UPDATE_FINANCEMENT_BIEN_TAUX_EMPRUNT:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            financement: {
              ...state.bien.qualification_robot.financement,
              taux_emprunt: action.payload,
            },
          },
        },
      };
    case UPDATE_FINANCEMENT_BIEN_TAUX_ASSURANCE:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            financement: {
              ...state.bien.qualification_robot.financement,
              taux_assurance: action.payload,
            },
          },
        },
      };
    case UPDATE_FINANCEMENT_BIEN_FRAIS_GARANTIES_CAUTION:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            financement: {
              ...state.bien.qualification_robot.financement,
              frais_garanties_cuationnement: action.payload,
            },
          },
        },
      };
    case UPDATE_FINANCEMENT_BIEN_FRAIS_COURTAGE:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            financement: {
              ...state.bien.qualification_robot.financement,
              frais_courtage: action.payload,
            },
          },
        },
      };
    case UPDATE_FINANCEMENT_BIEN_FRAIS_DOSSIER_FINANCEMENT:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            financement: {
              ...state.bien.qualification_robot.financement,
              frais_dossier_financement: action.payload,
            },
          },
        },
      };
    case UPDATE_FINANCEMENT_BIEN_MENSUALITE_REMBOURSEMENT_ASSURANCE:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            financement: {
              ...state.bien.qualification_robot.financement,
              montant_mensualite_assurance: action.payload,
            },
          },
        },
      };
    case UPDATE_FINANCEMENT_BIEN_MENSUALITE_REMBOURSEMENT_HORS_ASSURANCE:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            financement: {
              ...state.bien.qualification_robot.financement,
              montant_mensualite_hors_assurance: action.payload,
            },
          },
        },
      };
    case UPDATE_FINANCEMENT_BIEN_MENSUALITE_REMBOURSEMENT:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            financement: {
              ...state.bien.qualification_robot.financement,
              montant_mensualite_remboursement: action.payload,
            },
          },
        },
      };
    case UPDATE_FINANCEMENT_BIEN_TABLEAU_AMORTISSEMENT_PRET:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            financement: {
              ...state.bien.qualification_robot.financement,
              tableau_amortissement_pret: action.payload,
            },
          },
        },
      };
    case UPDATE_FISCALITE_BIEN_OPTION_FISCALE:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            fiscalite: {
              ...state.bien.qualification_robot.fiscalite,
              option_fiscale: action.payload,
            },
          },
        },
      };
    case UPDATE_FISCALITE_BIEN_REVENU_FONCIER_ANNUEL:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            fiscalite: {
              ...state.bien.qualification_robot.fiscalite,
              revenu_foncier_annuel: action.payload,
            },
          },
        },
      };
    case UPDATE_FISCALITE_BIEN_REVENU_FONCIER_ANNUEL_GLOBAL:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            fiscalite: {
              ...state.bien.qualification_robot.fiscalite,
              revenu_foncier_global: action.payload,
            },
          },
        },
      };
    case UPDATE_FISCALITE_BIEN_TABLEAU_CHARGE_INTERET:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            fiscalite: {
              ...state.bien.qualification_robot.fiscalite,
              tableau_charges_interets_annuel: action.payload,
            },
          },
        },
      };
    case UPDATE_FISCALITE_BIEN_CHARGE_INTERET_MOY_5ANS:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            fiscalite: {
              ...state.bien.qualification_robot.fiscalite,
              charges_interets_moyen_5_ans: action.payload,
            },
          },
        },
      };
    case UPDATE_FISCALITE_BIEN_NBE_ANNEE_DEFICIT_FONCIER:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            fiscalite: {
              ...state.bien.qualification_robot.fiscalite,
              nbe_annee_deficit_foncier: action.payload,
            },
          },
        },
      };
    case UPDATE_FISCALITE_BIEN_MONTANT_AMORTISSEMENT_BIEN_ANNUEL:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            fiscalite: {
              ...state.bien.qualification_robot.fiscalite,
              montant_amortissement_bien_annuel: action.payload,
            },
          },
        },
      };
    case UPDATE_FISCALITE_BIEN_TAB_CHARGES_INTERETS_ANNUEL:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            fiscalite: {
              ...state.bien.qualification_robot.fiscalite,
              tableau_charges_interets_annuel: action.payload,
            },
          },
        },
      };
    case UPDATE_RENDEMENT_PV:
      return {
        ...state,
        bien: {
          ...state.bien,
          qualification_robot: {
            ...state.bien.qualification_robot,
            rendement: {
              ...state.bien.qualification_robot.rendement,
              rendement_net_retenue: action.payload.rendement_net_retenue,
              rendement_brut_retenue: action.payload.rendement_brut_retenue,
              rendement_net_location_nue:
                action.payload.rendement_net_location_nue,
              rendement_net_colocation_nue:
                action.payload.rendement_net_colocation_nue,
              rendement_brut_colocation:
                action.payload.rendement_brut_colocation,
              rendement_brut_location_meuble:
                action.payload.rendement_brut_location_meuble,
              rendement_brut_location_nue:
                action.payload.rendement_brut_location_nue,
              rendement_net_location_meuble:
                action.payload.rendement_net_location_meuble,
            },
          },
        },
      };
    case SEND_ANNONCE_A_QUALIFIER:
      return {
        ...state,
        sendAnnonceAQualifier: action.payload,
      };
    case GET_BIEN_SIMILAIRE_VENTE:
      return {
        ...state,
        bienSimilaireVente: action.payload,
      };
    case GET_BIEN_SIMILAIRE_LOCATION:
      return {
        ...state,
        bienSimilaireLocation: action.payload,
      };
    case GET_BIEN_NOTAIRE:
      return {
        ...state,
        bienSimilaireNotaire: action.payload,
      };
    case UPDATE_FINANCEMENT_BIEN_TABLEAU_CAPITAL_REMBOURSE:
      return {
        ...state,
        capital_rembourse: action.payload,
      };
    case UPDATE_FINANCEMENT_BIEN_TABLEAU_REVENU_AVANT_TRAVAUX:
      return {
        ...state,
        revenu_avant_travaux: action.payload,
      };
    //UPDATE_CASH_FLOW_MOYEN
    case UPDATE_CASH_FLOW_MOYEN:
      return {
        ...state,
        cash_flow: action.payload,
      };
    default:
      return state;
  }
  console.log("action");
  return state;
}
