import React, { useState, useEffect } from "react";
import socketIOClient from "socket.io-client";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Provider } from "react-redux";
import loadable from "@loadable/component";
import axios from "axios";
import store from "./store";
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";
import setAuthToken from "./shared/functions/setAuthToken";
import {
  setCurrentUser,
  logoutUser,
  logoutSession,
} from "./actions/authActions";

import {getUserliste} from "./actions/bienActions"
import { useClearCache } from "react-clear-cache";
import PrivateRoute from "./components/private-route/PrivateRoute";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import "./App.css";
import { useAlert } from "react-alert";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DossierDetaille from "./components/annonce/DossierDetaille/DossierDetaille";
const { detect } = require("detect-browser");
const { APIServerUrl, APIFrontUrl } = require("./shared/values/environnement");
// export const socket = socketIOClient.connect(APIServerUrl)

// export const SocketContext = React.createContext();
// Analyse location
const StatDashboard = loadable(() =>
  import("./components/stats-dashboard/dashboard/Dashboardstat")
);

const FicheCommune = loadable(() =>
  import("./components/commune/FicheCommune")
);
const RechercheLocalisation = loadable(() =>
  import("./components/localisation/RechercheLocalisation")
);
//import Map  = loadable(() => import("./components/map/MapApp'

// Layout components
const NavBar = loadable(() => import("./components/layout/NavBar"));
const MobileNavBar = loadable(() => import("./components/layout/MobileNavBar"));
const FilArianne = loadable(() => import("./components/layout/FilArianne"));
const Footer = loadable(() => import("./components/layout/Footer"));

// Autres pages
const MentionsLegales = loadable(() =>
  import("./components/mentions-legales/MentionsLegales")
);
const CGU = loadable(() => import("./components/mentions-legales/CGU"));

//import Login  = loadable(() => import("./components/auth/Login'
const SeConnecter = loadable(() => import("./components/user/SeConnecter"));
const Register = loadable(() => import("./components/auth/Register"));
const Verif = loadable(() => import("./components/verification/verif"));
const Verifpay = loadable(() =>
  import("./components/verification/validitePay")
);
const NotFound = loadable(() => import("./components/not-found/NotFound"));
const GestionErreur = loadable(() =>
  import("./components/common-component/GestionErreur")
);
const ForgotPassword = loadable(() =>
  import("./components/auth/ForgotPassword")
);
const ResetPassword = loadable(() => import("./components/auth/ResetPassword"));
const Checkout = loadable(() => import("./components/Checkout/Checkout"));

// Annonces components
const SyntheseAnnonceNonqualif = loadable(() =>
  import("./components/annonce/synthese-annonce/SyntheseAnnonceNonqualif")
);
const Annonce_detail = loadable(() =>
  import("./components/annonce/annonce-non-investisseurs/Annonce_detail")
);

const Terrain_detail = loadable(() =>
  import("./components/annonce/terrain/Terrain_detail")
);
const List_annonces_non_inves = loadable(() =>
  import(
    "./components/annonce/annonce-non-investisseurs/List_annonces_non_inves"
  )
);

const List_annonces_critere = loadable(() =>
  import("./components/annonce/annonce-non-investisseurs/List_annonces_critere")
);

const ListeAnnonce = loadable(() =>
  import("./components/annonce/liste-annonce/ListeAnnonce")
);
const Terrain = loadable(() => import("./components/annonce/terrain/terrain"));
const SyntheseAnnoncePdf = loadable(() =>
  import("./components/annonce/synthese-annonce/SyntheseAnnoncePdf")
);
const FicheAnnonce = loadable(() =>
  import("./components/annonce/edit-fiche-annonce/FicheAnnonce")
);
const FicheAnnonce2 = loadable(() =>
  import("./components/annonce/edit-fiche-annonce/FicheAnnonce2")
);
const DossierBienDetaille = loadable(() =>
  import("./components/annonce/dossier-detaille/DossierBienDetaille")
);
const AddAnnonce = loadable(() =>
  import("./components/annonce/add-annonce/AddAnnonce")
);

// Client components
const ListeGestionnaire = loadable(() =>
  import("./components/gestionnaire/ListeGestionnaire")
);
const ListeClient = loadable(() => import("./components/client/ListeClient"));
const ListeGest = loadable(() => import("./components/client/ListGest"));
const ListeUser = loadable(() =>
  import("./components/user/liste-user/ListeUser")
);
const FicheUser = loadable(() =>
  import("./components/user/fiche-user/FicheUser")
);
//import UserList  = loadable(() => import("./components/user-list/UserList'
const PortefeuilleBien = loadable(() =>
  import("./components/portefeuille/PortefeuilleBien")
);

const Payment = loadable(() => import("./components/payment/payment"));
const Success = loadable(() => import("./components/CardSection/Success"));

// Message components
const MessagePersonneUnique = loadable(() =>
  import("./components/message/MessagePersonneUnique")
);
const MesMessages = loadable(() => import("./components/message/MesMessages"));

// Super admin components
const DashboardAdmin = loadable(() =>
  import("./components/dashboard/DashboardAdmin")
);

const DossierPublic = loadable(() =>
  import("./components/annonce/DossierPublic/DossierPublic.js")
);

// const DossierDetaille = loadable(() =>
//   import("./components/annonce/DossierDetaille/DossierDetaille.js")
// );
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
// Sentry.init({
//   dsn: "https://6bb86dddea4f4cbdbee25b1574e82f07@o652204.ingest.sentry.io/5780337",
//   release: "wealzy-front-react@" + process.env.npm_package_version,
//   integrations: [new Integrations.BrowserTracing({
//     // ... other options
//   })],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });
// Check for token

if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info
  const decoded = jwt_decode(localStorage.jwtToken);

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expire token
  const currentTime = Date.now() / 1000;

  if (decoded.exp < currentTime) {
    // Logout User

    //localStorage.setItem('linkzmkejf',"aa")
    store.dispatch(logoutUser());

    // Redirect to SeConnecter
    window.location.href = "/login";
  }
}

const App = (props) => {
  useEffect(() => {
    console.log("aaaaaa", window.location.pathname);
    if (window.location.pathname.length > 1) {
      localStorage.setItem("linked", window.location.href);
    }
  }, []);
  useEffect(()=>{
    if(localStorage.getItem("id")){
      props.getUserliste(localStorage.getItem("id"))
    }
  },[])
  const [IPv4, setIP] = useState("");
  //get adress ip
  useEffect(() => {
    axios.get("https://geolocation-db.com/json/").then((res) => {
      setIP(res.data.IPv4);
      //cbon
    });
  }, []);

  const browser = detect();

  const data = { browser, IPv4 };

  localStorage.setItem("ip", IPv4);
  localStorage.setItem("bn", browser.name);
  localStorage.setItem("bos", browser.os);
  localStorage.setItem("bv", browser.version);

  //    var ids=""
  //   socket.on('connect',()=>{

  //      ids=socket.id
  //       localStorage.setItem('idSocket',ids)
  //      })

  //   socket.emit('join',data)
  //     socket.emit('connectid',ids)
  //   socket.on('typing',(user,idS)=>{

  //     console.log("here_user_logged_in",user,localStorage.getItem("Email"))
  //     if(user===localStorage.getItem("Email")){
  //        props.logoutSession()
  //           //console.log("hawwwaaahou ay")
  //     }

  //   })
  const useStyles = makeStyles((theme) => ({
    root: {
      top: "80px",
      width: "400px",
      [theme.breakpoints.down("xs")]: {
        top: "45px",
        marginLeft: "auto",
        marginRight: "auto",
      },
      [theme.breakpoints.down("sm")]: {
        width: "300px",
      },
    },
  }));

  // window.onbeforeunload = () => {
  //   props.logoutUser()
  // }
  const classes = useStyles();
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  // useEffect(()=>{
  //  if(!isLatestVersion){
  //    console.log('dkhal')
  //    emptyCacheStorage()
  //  }
  // },[isLatestVersion])
  return (
    <SnackbarProvider
      classes={{
        root: classes.root,
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <div className="App">
            <Route exact path="/" component={SeConnecter} />
            <Route
              path="/"
              render={(props) =>
                props.location.pathname.includes("/in") && (
                  <NavBar key={window.location.pathname} />
                )
              }
            />
            <Route
              path="/"
              render={(props) =>
                props.location.pathname.includes("/in") && (
                  <MobileNavBar key={window.location.pathname} />
                )
              }
            />
            <Route
              path="/"
              render={(props) =>
                props.location.pathname.includes("/in") && (
                  <FilArianne key={window.location.pathname} />
                )
              }
            />
            <Route exact path="/register" component={Register} />
            <Route exact path="/validite" component={Verifpay} />
            <Route exact path="/verif/:email" component={Verif} />
            <Route exact path="/login" component={SeConnecter} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/reset/:token" component={ResetPassword} />
            <Route exact path="/mentions-legales" component={MentionsLegales} />
            <Route exact path="/CGU" component={CGU} />
            <Route exact path="/shared/:id_annonce" component={DossierPublic} />

            <>
              <GestionErreur>
                <div className="container">
                  <Route exact path="/checkout" component={Checkout} />
                  <Route exact path="/update/checkout" component={Checkout} />

                  <Route exact path="/not-found" component={NotFound} />
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/in/dashboard-admin"
                      component={DashboardAdmin}
                    />
                    <PrivateRoute
                      exact
                      path="/in/mentions-legales"
                      component={MentionsLegales}
                    />
                    <PrivateRoute
                      exact={true}
                      path="/in/mes-messages/:connected_user_id"
                      component={MesMessages}
                    />
                    <PrivateRoute
                      exact
                      path="/in/message/:connected_user_id/:in_communication_user_id"
                      component={MessagePersonneUnique}
                    />
                    <PrivateRoute
                      exact
                      path="/in/message-gestionnaire/:connected_user_id/:in_communication_user_id"
                      component={MessagePersonneUnique}
                    />
                    <PrivateRoute
                      exact
                      path="/in/mes-messages/:connected_user_id/investisseur/:in_communication_user_id"
                      component={MessagePersonneUnique}
                    />
                    <PrivateRoute
                      exact
                      path="/in/annonce/liste-annonce"
                      component={ListeAnnonce}
                    />

                    {/* <PrivateRoute
                    exact
                    path="/in/annonce/terrain"
                    component={Terrain}
                  /> */}
                    <PrivateRoute
                      exact
                      path="/in/user/stats"
                      component={StatDashboard}
                    />

                    <PrivateRoute
                      exact
                      path="/in/annonce/List-annonces-non-inves"
                      component={List_annonces_non_inves}
                    />
                    <PrivateRoute
                      exact
                      path="/in/annonce/List-annonces-non-inves-critere/:id"
                      component={List_annonces_critere}
                    />

                    <PrivateRoute
                      exact
                      path="/in/annonce/List-annonces-non-inves/detail/:id_annonce"
                      component={Annonce_detail}
                    />
                    {/* <PrivateRoute
                    exact
                    path="/in/annonce/Terrain/detail/:id_annonce"
                    component={Terrain_detail}
                  /> */}

                    <PrivateRoute
                      exact
                      path="/in/localisation/recherche"
                      component={RechercheLocalisation}
                    />
                    <PrivateRoute
                      exact
                      path="/in/localisation/commune/:code_insee"
                      component={FicheCommune}
                    />
                    <PrivateRoute
                      exact
                      path="/in/localisation/commune/:code_insee/quartier/:code_iris"
                      component={FicheCommune}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille/:id_user"
                      component={PortefeuilleBien}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille-gestionnaire/:id_user"
                      component={PortefeuilleBien}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille-user/:id_user"
                      component={PortefeuilleBien}
                    />
                    <PrivateRoute
                      exact
                      path="/in/mon-portefeuille/:id_user"
                      component={PortefeuilleBien}
                    />
                    <PrivateRoute
                      exact
                      path="/in/mon-portefeuille/:id_user/annonce/dossier-detaille/:id_annonce"
                      component={DossierDetaille}
                    />
                    <PrivateRoute
                      exact
                      path="/in/mon-portefeuille/:id_user/annonce/brut/dossier-detaille/:id_annonce"
                      component={DossierDetaille}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille/:id_user/annonce/dossier-detaille/:id_annonce"
                      component={DossierDetaille}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille-gestionnaire/:id_user/annonce/dossier-detaille/:id_annonce"
                      component={DossierDetaille}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille-user/:id_user/annonce/dossier-detaille/:id_annonce"
                      component={DossierDetaille}
                    />
                    <PrivateRoute
                      exact
                      path="/in/mon-portefeuille/:id_user/annonce/edit/:id_annonce"
                      component={FicheAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/mon-portefeuille/:id_user/annonce/edit2/:id_annonce"
                      component={FicheAnnonce2}
                    />
                                        <PrivateRoute
                      exact
                      path="/in/mon-portefeuille/:id_user/annonce/edit2/brut/:id_annonce"
                      component={FicheAnnonce2}
                    />
                    <PrivateRoute
                      exact
                      path="/in/annonce/edit2/brut/:id_annonce"
                      component={FicheAnnonce2}
                    />
                    <PrivateRoute
                      exact
                      path="/in/mon-portefeuille/:id_user/annonce/synthese_pdf/:id_annonce"
                      component={SyntheseAnnoncePdf}
                    />
                    <PrivateRoute
                      exact
                      path="/in/mon-portefeuille/:id_user/annonce/add"
                      component={AddAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/mon-portefeuille/:id_user/annonce/add/edit/:id_annonce"
                      component={FicheAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille/:id_user/annonce/edit/:id_annonce"
                      component={FicheAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille/:id_user/annonce/synthese_pdf/:id_annonce"
                      component={SyntheseAnnoncePdf}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille/:id_user/annonce/add"
                      component={AddAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille/:id_user/annonce/add/edit/:id_annonce"
                      component={FicheAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille-gestionnaire/:id_user/annonce/edit/:id_annonce"
                      component={FicheAnnonce}
                    />
                     <PrivateRoute
                      exact
                      path="/in/portefeuille-gestionnaire/:id_user/annonce/edit2/:id_annonce"
                      component={FicheAnnonce2}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille-gestionnaire/:id_user/annonce/synthese_pdf/:id_annonce"
                      component={SyntheseAnnoncePdf}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille-gestionnaire/:id_user/annonce/add"
                      component={AddAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille-gestionnaire/:id_user/annonce/add/edit/:id_annonce"
                      component={FicheAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille-user/:id_user/annonce/edit/:id_annonce"
                      component={FicheAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille-user/:id_user/annonce/synthese_pdf/:id_annonce"
                      component={SyntheseAnnoncePdf}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille-user/:id_user/annonce/add"
                      component={AddAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/portefeuille-user/:id_user/annonce/add/edit/:id_annonce"
                      component={FicheAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/client/liste-clients"
                      component={ListeClient}
                    />
                      <PrivateRoute
                      exact
                      path="/in/gestionnaire/liste-recherche/:id"
                      component={ListeGest}
                    />
                    <PrivateRoute
                      exact
                      path="/in/client/edit/:id_user"
                      component={FicheUser}
                    />
                    <PrivateRoute
                      exact
                      path="/in/gestionnaire/liste-gestionnaire"
                      component={ListeGestionnaire}
                    />
                    <PrivateRoute
                      exact
                      path="/in/gestionnaire/edit/:id_user"
                      component={FicheUser}
                    />
                    <PrivateRoute
                      exact
                      path="/in/gestionnaire/add/"
                      component={FicheUser}
                    />

                    <PrivateRoute
                      exact
                      path="/in/mon-compte/:id_user"
                      component={FicheUser}
                    />
                    <PrivateRoute
                      exact
                      path="/in/client/add/"
                      component={FicheUser}
                    />
                    <PrivateRoute
                      exact
                      path="/in/user/edit/:id_user"
                      component={FicheUser}
                    />
                    <PrivateRoute
                      exact
                      path="/in/user/add/"
                      component={FicheUser}
                    />
                    <PrivateRoute
                      exact
                      path="/in/user/liste-utilisateurs"
                      component={ListeUser}
                    />
                    <Route exact path="/in/payment" component={Payment} />

                    <PrivateRoute
                      exact
                      path="/in/payment/update"
                      component={Payment}
                    />
                    <Route exact path="/success" component={Success} />
                    <PrivateRoute
                      exact
                      path="/in/annonce/edit/:id_annonce"
                      component={FicheAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/annonce/edit2/:id_annonce"
                      component={FicheAnnonce2}
                    />
                    <PrivateRoute
                      exact
                      path="/in/annonce/dossier-detaille/:id_annonce"
                      component={DossierDetaille}
                    />
                    <PrivateRoute
                      exact
                      path="/in/annonce/add/edit/:id_annonce"
                      component={FicheAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/annonce/add"
                      component={AddAnnonce}
                    />
                    <PrivateRoute
                      exact
                      path="/in/annonce/newdossier/:id"
                      component={DossierDetaille}
                    />
                    <PrivateRoute
                      exact
                      path="/in/annonce/synthese_pdf/:id_annonce"
                      component={SyntheseAnnoncePdf}
                    />
                    <PrivateRoute
                      exact
                      path="/in/annonce/synthese_pdf_Non_qualif/:id_annonce"
                      component={SyntheseAnnonceNonqualif}
                    />

                    <Route path="/in" component={NotFound} />
                  </Switch>
                </div>
              </GestionErreur>
            </>

            <Route
              path="/"
              render={(props) =>
                props.location.pathname.includes("/in") && <Footer />
              }
            />
          </div>
        </Router>
      </Provider>
    </SnackbarProvider>
  );
};
App.propTypes = {
  logoutUser: PropTypes.func,
  logoutSession: PropTypes.func,
  getUserliste:PropTypes.func.isRequired,
};
export default connect(null, { logoutUser, logoutSession ,getUserliste})(App);
// export default App
