import React, { useEffect, useState } from "react";
import DonnesDuMarche from "./DonnesDuMarche/DonnesDuMarche";
import DetailBien from "./DetailBien/DetailBien";
import DossierBienDetailleModeleFinancier from "../dossier-detaille/DossierBienDetailleModeleFinancier";
import FicheAnnonceSynthese from "../edit-fiche-annonce/FicheAnnonceSynthese";
import SectionPhoto from "./SectionPhoto/SectionPhoto";
import Couverture from "./Couverture/Couverture";
import { Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { generatepdfsettingnew } from "../../../shared/functions/generatePdfSettings";
import SelectModal from "../../common-component/SelectModal";
import waitimg from "../../../img/waiting.svg.svg";
import { useHistory, useParams, Link } from "react-router-dom";
import { Tooltip, Fab, CircularProgress } from "@material-ui/core";
import domtoimage from "dom-to-image";
import pdfMake from "pdfmake/build/pdfmake";
import PrintIcon from "@material-ui/icons/Print";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import FixedButtonHorizontalList from "../../button/FixedButtonHorizontalList";
import FixedButtonItem from "../../button/FixedButtonItem";
import SectionPlan from "./SectionPlan/SectionPlan";
import BackDropLoader from "../../loader/BackDropLoader";
import PDFPageFin from "../../common-component/PDFPageFin";
import { connect } from "react-redux";
import ShareIcon from "@mui/icons-material/Share";
// Notifications
import { useSnackbar } from "notistack";
import {
  get_bien_from_wallet,
  getDataBienFromWallet,
} from "../../../actions/bienActions";
const useStyles = makeStyles((theme) => ({
  circularProgress: {
    color: "white",
  },
  paper: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    height: "210mm",
    width: "297mm",
    marginTop: "10px",
    padding: 12,
    breakAfter: "page",
    position: "relative",
    ["@media (max-width:800px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "80%",
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",

      padding: theme.spacing(1),
    },
    "@media print": {
      height: "209.5mm",
      width: "297mm",
      padding: theme.spacing(0),
      borderRadius: 0,
      //border: '1px solid ' + theme.palette.primary.main
    },
  },
  hidden: {
    display: "none",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    width: "100%",
  },
  gridList: {},
  gridTilePortrait: {
    height: "auto",
    width: "100%",
    minHeight: "100%",
    maxWidth: "100%",
  },
  gridTileLandscape: {
    height: "100%",
    width: "auto",
    maxHeight: "100%",
    minWidth: "100%",
  },
  cardValue: {
    fontSize: "1.6rem",
    color: theme.palette.secondary.main,
  },
  card2: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    height: "162px",
    borderColor: "#eee",
    borderStyle: "solid",
    borderRadius: "4px",
    boxShadow: "none",
    position: "relative",
  },
  card: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    height: "192px",
    borderColor: "#eee",
    borderStyle: "solid",
    borderRadius: "4px",
    boxShadow: "none",
    position: "relative",
    [theme.breakpoints.down("xs")]: {},
  },
  cardContent: {
    padding: 0,
  },

  cardSubtitle: {
    fontSize: "0.8rem",
    color: theme.palette.primary.main,
  },
  cardSubtitle2: {
    fontSize: "0.8rem",
    color: theme.palette.primary.light,
    bottom: 0,
    position: "absolute",
    margin: 0,
    paddingBottom: theme.spacing(0),
  },
}));

const DossierDetaille = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const str = "Données du marché";

  const classes = useStyles();
  const history = useHistory();
  const from = history.location.pathname;
  const annonceFromWallet = from.includes("portefeuille") ? true : false;
  const annonceFromMyWallet = from.includes("mon-portefeuille") ? true : false;
  const fromGestionnaire = from.includes("portefeuille-gestionnaire")
    ? true
    : false;
  const isbrut = from.includes("brut") ? true : false;
  let { id_annonce, id_user } = useParams();

  const idWalletUser = id_user;
  const [bien, setBien] = useState([]);

  const [Plans, setPlans] = useState(bien[0]?.plans?.length > 0 ? true : false);
  const [selectedValue, setSelectedValue] = useState("test@test.com");
  const [open, setOpen] = useState(false);
  const [idshared, setidshared] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [progress, setprogress] = useState(0);
  const [printing, setprinting] = useState(true);
  const [checkboxes, setcheckboxes] = useState({
    couverture: true,
    sommaire: true,
    detailmarche_bien: true,
    detail_bien: true,
    chiffres_cles: true,
  });
  const [isLoadingBien, setIsLoadingBien] = useState(true);
  const [PreparePdfLoading, setPreparePdfLoading] = useState(false);
  const [progresslabels, setprogresslabel] = useState("Initialisation ...");
  const [currentimg, setcurrentimg] = useState(waitimg);
  const [imgArray, setimgArray] = useState([]);
  const [pdfSetting, setpdfSetting] = useState([]);
  var progresscounter = 1;
  const handleprogress = (progresslabel) => {
    setprogresslabel(progresslabel);
    let total = 6 + bien[0].url_photo.length + bien[0].plans.length;
    let step = (progresscounter / total) * 100;

    if (progresscounter <= total) {
      setprogress(progress + step);
      progresscounter++;
    }
  };

  const handle_generate_state = async (value) => {
    // setcheckboxes(value);
    // console.log("aaa", checkboxes);
    // generatePDF();
    var nomFichier =
      "[ WEALZY ]-Dossier investissement-" +
      bien[0].code_postal +
      "-" +
      bien[0].nom_commune +
      "-" +
      bien[0].type_bien_corrige +
      " " +
      bien[0].nbe_piece +
      "p-" +
      bien[0].surface +
      ".pdf";
    let pdf = await pdfMake.createPdf(pdfSetting);
    pdf.download(nomFichier);

    setOpen(false);

    setprogress(0);
    setprinting(false);
    enqueueSnackbar("votre téléchargement commence bientôt", {
      variant: "success",
      autoHideDuration: 6000,
    });
  };
  const loadingRender = () => {
    return <BackDropLoader />;
  };
  const menuRender = () => {
    return (
      <FixedButtonHorizontalList>
        {/* {[0, 1, 2, 3, 4, 10].includes(props.roleConnectedUser) && ( */}
        <FixedButtonItem>
          <Tooltip title={"Modifier le bien"}>
            {bien !== undefined && (
              <Fab
                color={"primary"}
                aria-label="Modifier Annonce"
                component={Link}
                to={
                  annonceFromWallet
                    ? "/in/" +
                      (annonceFromMyWallet
                        ? "mon-portefeuille/"
                        : fromGestionnaire
                        ? "portefeuille-gestionnaire/"
                        : "portefeuille/") +
                      idWalletUser +
                      "/annonce/edit2/" +
                      id_annonce
                    : "/in/annonce/edit2/" + id_annonce
                }
              >
                <EditIcon />
              </Fab>
            )}
          </Tooltip>
        </FixedButtonItem>
        {/* // )} */}
        {/* {[0, 1, 2, 3, 4, 10].includes(props.roleConnectedUser) && (
          // <BoutonAddBienWalletUser
          //   dataBien={dataBien}
          //   annonceFromWallet={annonceFromWallet}
          //   inUserWallet={inUserWallet}
          //   idWalletUser={idWalletUser}
          // />
        )} */}
        <FixedButtonItem>
          <Tooltip title="Enregistrer">
            <Fab color={"primary"} aria-label="add">
              {isSaving ? (
                <CircularProgress className={classes.circularProgress} />
              ) : (
                <SaveIcon />
              )}
            </Fab>
          </Tooltip>
        </FixedButtonItem>
        <FixedButtonItem>
          <Tooltip title={"Générer un rapport PDF"}>
            <Fab
              color={"primary"}
              onClick={() => {
                // onClickPrint()
                //
                handleClickOpen();
              }}
            >
              {PreparePdfLoading ? (
                <CircularProgress className={classes.circularProgress} />
              ) : (
                <PrintIcon />
              )}
            </Fab>
          </Tooltip>
        </FixedButtonItem>
        <FixedButtonItem>
          <Tooltip title={"Partager"}>
            {bien !== undefined && (
              <a
                href={window.location.origin + "/shared/" + idshared}
                target="_blank"
              >
                <Fab color={"primary"} aria-label="Partager l'annonce">
                  <ShareIcon />
                </Fab>
              </a>
            )}
          </Tooltip>
        </FixedButtonItem>
        <FixedButtonItem>
          {/* <Tooltip title="Accès à une section">{menuSommaire()}</Tooltip> */}
        </FixedButtonItem>
      </FixedButtonHorizontalList>
    );
  };

  const printdiagRender = () => {
    return (
      <div>
        <SelectModal
          type={true}
          handle_generate={handle_generate_state}
          progresslabels={progresslabels}
          selectedValue={selectedValue}
          progress={progress}
          imgcurr={currentimg}
          open={open}
          onClose={handleClosemod}
          imgarray={imgArray}
          generate={generate_dom_images}
        />
      </div>
    );
  };
  const handleClosemod = (value) => {
    console.log("ici");
    setOpen(false);
    setSelectedValue(value);
  };
  const handleClickOpen = () => {
    console.log("ici");
    setOpen(true);
  };

  useEffect(() => {
    if (id_annonce) {
      props.getDataBienFromWallet(idWalletUser, id_annonce).then((res) => {
        console.log("icid5al", res);
        setidshared([res._id]);
        setBien([res]);
        setIsLoadingBien(false);
      });
      // get_bien_from_wallet(id_annonce,idWalletUser)
      //   .then((res) => {
      //     setidshared([res.data._id])
      //     setBien([res.data]);
      //     setIsLoadingBien(false);
      //   })
      //   .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    console.log(open, "open");
    console.log(printing);
  }, [bien, open, printing]);

  const generate_dom_images = async () => {
    let imgarray = [];

    const couverture = document.getElementById("couverture");
    const chiffres_cles = document.getElementById("chiffres_cles");
    const detail_bien = document.getElementById("detail_bien");
    const detailmarche_bien = document.getElementById("detailmarche_bien");
    const photo_bien = document.getElementById("photo_bien");
    const DonnesDuMarche = document.getElementById("DonnesDuMarche");
    const Photo_Plan = document.getElementById("Photo_Plan");
    const footer = document.getElementById("fin");
    const scale = 6;
    try {
      const couvertures = await domtoimage.toPng(couverture, {
        width: couverture.clientWidth * scale,
        height: couverture.clientHeight * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
        },
      });
      handleprogress("Couverture");
      setcurrentimg(couvertures);

      imgarray.push({ img: couvertures, name: "couverture" });
    } catch (e) {
      console.log("couverture", e);
    }

    try {
      const chiffrescles = await domtoimage.toPng(chiffres_cles, {
        width: chiffres_cles.clientWidth * scale,
        height: chiffres_cles.clientHeight * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
        },
      });
      handleprogress("chiffres cles");
      setcurrentimg(chiffrescles);
      imgarray.push({ img: chiffrescles, name: "chiffres_cles" });
    } catch (e) {
      console.log("chiffres_cles", e);
    }

    try {
      const detail_biens = await domtoimage.toPng(detail_bien, {
        width: detail_bien.clientWidth * scale,
        height: detail_bien.clientHeight * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
        },
      });
      handleprogress("details du bien");
      setcurrentimg(detail_biens);
      imgarray.push({ img: detail_biens, name: "detail_biens" });
    } catch (e) {
      console.log("detail_biens", e);
    }

    bien[0].url_photo.forEach(async (photo, index) => {
      try {
        console.log("photo_bien_" + index);
        const photo_bien = document.getElementById("photo_bien_" + index);

        const photo_biens = await domtoimage.toPng(photo_bien, {
          width: photo_bien.clientWidth * scale,
          height: photo_bien.clientHeight * scale,
          style: {
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
          },
        });
        handleprogress("photo du bien");
        setcurrentimg(photo_biens);
        return imgarray.push({ img: photo_biens, name: "photo_bien" });
      } catch (e) {
        return console.log("photo_bien", e);
      }
    });
    bien[0].plans.forEach(async (plans, index) => {
      try {
        console.log("plans_bien_" + index);
        const Photo_Plani = document.getElementById("plans_bien_" + index);

        const Photo_Plans = await domtoimage.toPng(Photo_Plani, {
          width: Photo_Plani.clientWidth * scale,
          height: Photo_Plani.clientHeight * scale,
          style: {
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
          },
        });
        handleprogress("Plans du bien");
        setcurrentimg(Photo_Plans);
        return imgarray.push({ img: Photo_Plans, name: "Photo_Plan" });
      } catch (e) {
        return console.log("Plans du bien", e);
      }
    });

    // if (bien[0]?.plans?.length > 0) {
    //   try {
    //     const Photo_Plans = await domtoimage.toPng(Photo_Plan, {
    //       width: Photo_Plan.clientWidth * scale,
    //       height: Photo_Plan.clientHeight * scale,
    //       style: {
    //         transform: "scale(" + scale + ")",
    //         transformOrigin: "top left",
    //       },
    //     });
    //     handleprogress("Plans du bien");
    //     setcurrentimg(Photo_Plans);
    //     imgarray.push({ img: Photo_Plans, name: "Photo_Plan" });
    //   } catch (e) {
    //     console.log("Photo_Plan", e);
    //   }
    // }
    try {
      const DonnesDuMarches = await domtoimage.toPng(DonnesDuMarche, {
        width: DonnesDuMarche.clientWidth * scale,
        height: DonnesDuMarche.clientHeight * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
        },
      });
      handleprogress("Donnes du marché");
      setcurrentimg(DonnesDuMarches);
      imgarray.push({ img: DonnesDuMarches, name: "Donnes_Du_Marche" });
    } catch (e) {
      console.log("DonnesDuMarche", e);
    }
    try {
      const detailmarche_biens = await domtoimage.toPng(detailmarche_bien, {
        width: detailmarche_bien.clientWidth * scale,
        height: detailmarche_bien.clientHeight * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
        },
      });
      handleprogress("detail marché");
      setcurrentimg(detailmarche_biens);
      imgarray.push({ img: detailmarche_biens, name: "detailmarche_bien" });
    } catch (e) {
      console.log("detailmarche_bien", e);
    }

    try {
      const fincover = await domtoimage.toPng(footer, {
        width: footer.clientWidth * scale,
        height: footer.clientHeight * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
        },
      });
      handleprogress("Footer du document");
      setcurrentimg(fincover);
      imgarray.push({
        img: fincover,
        name: "fin",
      });
    } catch (e) {
      console.log("fin", e);
    }
    setimgArray(imgarray);
    var pdfExportSetting = generatepdfsettingnew(imgarray);
    setpdfSetting(generatepdfsettingnew(imgarray));
    setPreparePdfLoading(false);
    handleprogress("tâche terminée");
  };

  const handlePlans = (e) => {
    console.log("aaaaaa");
    setPlans(true);
    get_bien_from_wallet(id_annonce, idWalletUser)
      .then((res) => {
        console.log("lahneee", res.data);
        setBien([res.data]);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    console.log("biennnnnnnnnnn", bien);
    if (bien[0]?.plans?.length > 0) {
      setPlans(true);
      // generatePDF();
    }
  }, [bien]);

  useEffect(() => {
    if (isLoadingBien === false) {
      // setPreparePdfLoading(true);
      // generate_dom_images();
      // generatePDF();
    }
  }, [isLoadingBien]);

  if (isLoadingBien) {
    return <>{loadingRender()}</>;
  } else {
    return (
      <>
        {bien.length !== 0 && (
          <div>
            <Paper className={classes.paper} id={"couverture"}>
              <Couverture
                logo={props.connectedUser.logo_entreprise}
                bien={bien[0]}
                isbrut={isbrut ? isbrut : false}
              />
            </Paper>
            {!isbrut && (
              <Paper className={classes.paper} id="chiffres_cles">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#fff",
                      fontSize: "40px",
                      backgroundColor: "#122659",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <span>1</span>
                  </div>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ color: "#122659", marginLeft: "10px" }}
                  >
                    CHIFFRES CLES
                  </Typography>
                </div>
                <div>
                  <FicheAnnonceSynthese
                    pcolor={""}
                    scolor={""}
                    toPrint={false}
                    callback={() => {}}
                    dataBien={bien[0]}
                  />
                </div>
              </Paper>
            )}

            <Paper className={classes.paper} id={"detail_bien"}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#fff",
                    fontSize: "40px",
                    backgroundColor: "#122659",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <span>2</span>
                </div>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ color: "#122659", marginLeft: "10px" }}
                >
                  DETAILS BIEN{" "}
                </Typography>
              </div>
              <DetailBien bien={bien[0]} />
            </Paper>

            {bien[0].url_photo.length != 0 &&
              bien[0].url_photo.map((img, index) => {
                return (
                  <Paper className={classes.paper} id={`photo_bien_${index}`}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            color: "#fff",
                            fontSize: "40px",
                            backgroundColor: "#122659",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "15px",
                          }}
                        >
                          <span>3</span>
                        </div>
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{ color: "#122659", marginLeft: "10px" }}
                        >
                          PHOTOS DU BIEN
                        </Typography>
                      </div>
                    </div>

                    <SectionPhoto
                      className={"element"}
                      bien={bien}
                      print={printing}
                      img={img}
                    />
                  </Paper>
                );
              })}

            {bien[0].plans.length > 0
              ? bien[0].plans.map((item, index) => {
                  return (
                    <Paper className={classes.paper} id={`plans_bien_${index}`}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              color: "#fff",
                              fontSize: "40px",
                              backgroundColor: "#122659",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "15px",
                            }}
                          >
                            <span>3</span>
                          </div>
                          <Typography
                            variant="h6"
                            gutterBottom
                            style={{ color: "#122659", marginLeft: "10px" }}
                          >
                            PLANS DU BIEN
                          </Typography>
                        </div>
                        {/* <Typography
                    variant="h6"
                    gutterBottom
                    style={{ color: "#122659", marginLeft: "10px" }}
                  >
                    PHOTOS DU BIEN
                  </Typography> */}
                      </div>

                      <SectionPlan
                        big={true}
                        className={"element"}
                        bien={bien[0]}
                        print={printing}
                        item={item}
                        index={index}
                        from={1}
                      />
                    </Paper>
                  );
                })
              : ""}

            <Paper className={classes.paper} id="DonnesDuMarche">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#fff",
                    fontSize: "40px",
                    backgroundColor: "#122659",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <span>5</span>
                </div>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ color: "#122659", marginLeft: "10px" }}
                >
                  {str.toUpperCase()}
                </Typography>
              </div>
              <DonnesDuMarche bien={bien[0]} />
            </Paper>
            <Paper className={classes.paper} id="detailmarche_bien">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#fff",
                    fontSize: "40px",
                    backgroundColor: "#122659",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <span>6</span>
                </div>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ color: "#122659", marginLeft: "10px" }}
                >
                  SYNTHESE FINANCIERE
                </Typography>
              </div>
              <DossierBienDetailleModeleFinancier
                className={"element"}
                data={bien[0].qualification_robot}
                isbrut={isbrut ? isbrut : false}
              />{" "}
            </Paper>

            <Paper className={classes.paper} id="fin">
              <PDFPageFin
                logo={props.connectedUser.logo_entreprise}
                siteweb={props.connectedUser.siteweb_entreprise}
                raisonSociale={props.connectedUser.raison_sociale}
                telephone={props.connectedUser.telephone_entreprise}
                email={props.connectedUser.email_entreprise}
              />
            </Paper>
            {menuRender()}
            {printdiagRender()}
          </div>
        )}
      </>
    );
  }
};

const mapStateToProps = (state) => ({
  connectedUser: state.auth.user,
  dataBien: state.bien.bien,
});
export default connect(mapStateToProps, { getDataBienFromWallet })(
  DossierDetaille
);
